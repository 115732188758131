import isUndefined from 'lodash/isUndefined'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import type { PropsWithChildren } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import type { SystemsType } from '@/constants/systems'
import { useAppDispatch } from '@/redux-store/hooks'
import { useGetSystemsConfigurationQuery } from '@/redux-store/thingsboard/telemetry/telemetry.service'
import { setActiveSystems } from '@/redux-store/thingsboard/telemetry/telemetry.slice'

export default function AppBaseConfig(props: PropsWithChildren) {
  const { id = '' } = useParams()

  const { children } = props
  const { data: { systemsConfig } = {} } = useGetSystemsConfigurationQuery(id, { skip: !id })

  const dispatch = useAppDispatch()

  const activeSystems = useMemo(
    () =>
      keys(pickBy(systemsConfig, ({ exists }) => isUndefined(exists) || exists)) as SystemsType[],
    [systemsConfig]
  )

  useEffect(() => {
    dispatch(setActiveSystems(activeSystems))
  }, [activeSystems])

  return <>{children}</>
}
