import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { AssetsData } from '@/redux-store/thingsboard/assets/types'

export interface AssetsState {
  assets: Array<AssetsData>
  currentAsset: AssetsData
}

const initialState: AssetsState = {
  assets: [],
  currentAsset: {} as AssetsData,
}

export const assetsSlice = createSlice({
  initialState,
  name: 'assets',
  reducers: {
    setAssetsData: (state, action: PayloadAction<any>) => {
      state.assets = action.payload
    },
    setCurrentAsset: (state, action: PayloadAction<any>) => {
      state.currentAsset = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAssetsData, setCurrentAsset } = assetsSlice.actions

export default assetsSlice.reducer
