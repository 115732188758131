import { Space } from 'antd'
import { get, isEmpty, map, some, toPairs } from 'lodash'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import SystemConsumptionCard from '@/components/SystemConsumptionList/SystemConsumptionCard/SystemConsumptionCard'
import SystemConsumptionModal from '@/components/SystemConsumptionList/SystemConsumptionModal/SystemConsumptionModal'
import type { SystemsKeyType } from '@/constants/systems'
import { POLLING_INTERVAL_IN_MS } from '@/constants/utilities'
import { useAppSelector } from '@/redux-store/hooks'
import { getSelectedTimePeriodSelector } from '@/redux-store/thingsboard/telemetry/telemetry.selectors'
import {
  useGetEquipmentConsumptionTelemetryQuery,
  useGetSummaryTelemetryQuery,
  useGetSystemConsumptionTelemetryQuery,
  useGetSystemsFddStateQuery,
} from '@/redux-store/thingsboard/telemetry/telemetry.service'
import type {
  EquipmentConsumptionResponseValueType,
  RunningUsageChartDataByTimeType,
  SystemFddStateResponseValueType,
} from '@/redux-store/thingsboard/telemetry/types'

function SystemConsumptionList() {
  const { id = '' } = useParams()

  const { data: { systemsData } = {}, isLoading: isSystemsDataLoading } =
    useGetSystemConsumptionTelemetryQuery(id, {
      pollingInterval: POLLING_INTERVAL_IN_MS,
      skip: !id,
    })
  const { data: { systemsInfo } = {}, isLoading: isSystemsInfoLoading } =
    useGetEquipmentConsumptionTelemetryQuery(id, {
      pollingInterval: POLLING_INTERVAL_IN_MS,
      skip: !id,
    })
  const { currentData: { runningUsage } = {}, isLoading: isChartDataLoading } =
    useGetSummaryTelemetryQuery(id, {
      pollingInterval: POLLING_INTERVAL_IN_MS,
      skip: !id,
    })

  const { data: { fddState } = {}, isLoading: isFddStateLoading } = useGetSystemsFddStateQuery(id, {
    pollingInterval: POLLING_INTERVAL_IN_MS,
    skip: !id,
  })

  const selectedTimePeriod = useAppSelector(getSelectedTimePeriodSelector)

  const [selectedSystem, setSelectedSystem] = useState<SystemsKeyType>('' as SystemsKeyType)

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  function handleOnClickDetails(systemName: SystemsKeyType) {
    if (!isEmpty(systemsData)) {
      setSelectedSystem(systemName)
      setIsDetailsModalOpen(true)
    }
  }

  function handleOnCloseDetails() {
    setIsDetailsModalOpen(false)
  }

  return (
    <Space direction='vertical' className='w-100'>
      {!isEmpty(systemsData) && !isEmpty(systemsInfo) && (
        <>
          {map(toPairs(systemsData), ([systemName, systemDetails]) => {
            return (
              <SystemConsumptionCard
                isLoading={some([isSystemsDataLoading, isSystemsInfoLoading], Boolean)}
                key={systemName}
                title={systemName as SystemsKeyType}
                numOfItems={systemDetails?.equipList ?? 0}
                numOfInefficiencies={
                  systemsInfo[systemName as SystemsKeyType]?.main?.inefficiencies ?? 0
                }
                numOfFaults={systemsInfo[systemName as SystemsKeyType]?.main?.faults ?? 0}
                usage={{
                  carbon: systemDetails[selectedTimePeriod].carbon,
                  energy: systemDetails[selectedTimePeriod].energy,
                  fiscal: systemDetails[selectedTimePeriod].fiscal,
                }}
                onClickDetails={handleOnClickDetails}
              />
            )
          })}
          {!isEmpty(selectedSystem) && isDetailsModalOpen && (
            <SystemConsumptionModal
              isLoading={some([isSystemsInfoLoading, isChartDataLoading], Boolean)}
              isOpen={isDetailsModalOpen}
              onClose={handleOnCloseDetails}
              title={selectedSystem}
              chartData={get(runningUsage, selectedSystem) as RunningUsageChartDataByTimeType}
              equipment={get(systemsInfo, selectedSystem) as EquipmentConsumptionResponseValueType}
              fddStateData={get(fddState, selectedSystem) as SystemFddStateResponseValueType}
              isFddStateLoading={isFddStateLoading}
            />
          )}
        </>
      )}
    </Space>
  )
}
export default SystemConsumptionList
