import { Grid } from 'antd'

const { useBreakpoint } = Grid

function useScreenSize() {
  const screens = useBreakpoint()

  const isScreenMobile = screens.xs
  const isScreenTablet = screens.sm
  const isScreenDesktop = screens.lg

  return {
    isScreenDesktop,
    isScreenMobile,
    isScreenTablet,
  }
}

export default useScreenSize
