import { Button, Modal, Skeleton, Space, Tag, Typography } from 'antd'
import { omit } from 'lodash'
import React from 'react'

import Icon from '@/components/Icon/Icon'
import PerformanceChart from '@/components/SystemConsumptionList/SystemConsumptionModal/PerformanceChart/PerfromanceChart'
import SystemConsumptionDetailsTable from '@/components/SystemConsumptionList/SystemConsumptionModal/SystemConsumptionDetailsTable/SystemConsumptionDetailsTable'
import type { SystemsKeyType } from '@/constants/systems'
import { useAppSelector } from '@/redux-store/hooks'
import { getSelectedTimePeriodSelector } from '@/redux-store/thingsboard/telemetry/telemetry.selectors'
import type {
  EquipmentConsumptionResponseValueType,
  RunningUsageChartDataByTimeType,
  SystemFddStateResponseValueType,
} from '@/redux-store/thingsboard/telemetry/types'
import { capitalizeWordsAndReplaceUnderscores } from '@/utils/stringUtils'

type SystemConsumptionModalPropsType = {
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  title: string
  subtitle?: string
  chartData: RunningUsageChartDataByTimeType
  equipment: EquipmentConsumptionResponseValueType
  fddStateData: SystemFddStateResponseValueType
  isFddStateLoading: boolean
}

const { Text, Title } = Typography

export default function SystemConsumptionModal(props: SystemConsumptionModalPropsType) {
  const {
    isLoading,
    isOpen,
    onClose,
    title,
    subtitle = 'FDD & PLM',
    chartData,
    equipment,
    fddStateData = [],
    isFddStateLoading,
  } = props

  const selectedTimePeriod = useAppSelector(getSelectedTimePeriodSelector)

  const inefficiencies = equipment?.main.inefficiencies ?? 0
  const faults = equipment?.main.faults ?? 0

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      centered
      footer={null}
      closable={false}
      width={674}
      className='custom-modal ant-modal-content'
    >
      <div className='absolute r-17'>
        <Button
          onClick={onClose}
          icon={<Icon iconName='x' type='regular' className='general-white-black' />}
          type='ghost'
        />
      </div>
      <Space.Compact direction='vertical' block>
        <Skeleton active={isLoading} loading={isLoading} round>
          <Space>
            <Title level={2} className='general-white-black'>
              {capitalizeWordsAndReplaceUnderscores(title)}
            </Title>
          </Space>
          <Space align='center'>
            <Tag
              className={`b-radius-8 pt-0.25 pb-0.25 ${
                inefficiencies > 0 ? 'bg-orange' : 'bg-transparent'
              }`}
            >
              <Text
                className={`subtitle ${
                  inefficiencies > 0 ? 'general-white' : 'system-gray-500-300'
                }`}
              >
                {inefficiencies} INEFFICIENCIES
              </Text>
            </Tag>
            <Tag
              className={`b-radius-8 ${
                faults > 0 ? 'bg-general-red' : 'bg-transparent'
              } pt-0.25 pb-0.25`}
            >
              <Text className={`subtitle ${faults > 0 ? 'general-white' : 'system-gray-500-300'}`}>
                {faults} FAULTS
              </Text>
            </Tag>
            <Icon iconName='wrench' type='solid' size='xs' className='system-gray-700-100' />
            <Text className='s-medium system-gray-700-100'>{subtitle}</Text>
          </Space>
          <Space.Compact block className='mt-2'>
            <PerformanceChart
              title={title as SystemsKeyType}
              data={chartData[selectedTimePeriod]}
            />
          </Space.Compact>
          {equipment && (
            <SystemConsumptionDetailsTable
              equipment={omit(equipment, 'main') as EquipmentConsumptionResponseValueType}
              fddStateData={fddStateData}
              isFddStateLoading={isFddStateLoading}
            />
          )}
        </Skeleton>
      </Space.Compact>
    </Modal>
  )
}
