import { Space } from 'antd'

import Avatar from '@/components/Avatar/Avatar'
import LogoutButton from '@/components/LogoutButton/LogoutButton'
import MenuDropdown from '@/components/MenuDropdown/MenuDropdown'
import type { AuthorityEntityEnum } from '@/redux-store/thingsboard/authentication/types'
import { capitalizeWordsAndReplaceUnderscores } from '@/utils/stringUtils'

type ProfileMenuPropsType = {
  user: string
  role?: AuthorityEntityEnum
  logoutUser: () => void
}

export default function ProfileMenu(props: ProfileMenuPropsType) {
  const { user, role = '', logoutUser } = props

  return (
    <Space>
      <MenuDropdown
        header={{ subtitle: capitalizeWordsAndReplaceUnderscores(role), title: user }}
        triggerButton={<Avatar />}
        menuItems={[{ key: 'logout', label: <LogoutButton />, onClick: logoutUser }]}
      />
    </Space>
  )
}
