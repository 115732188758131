import { Space, Typography } from 'antd'
import { noop } from 'lodash'

import Icon from '@/components/Icon/Icon'
import { UtilityType } from '@/constants/utilities'

import styles from './PerformanceStatusCard.module.scss'

const { Text } = Typography

type PerformanceStatusCardProps = {
  isStatusSuccess: boolean
  utilityType: UtilityType
  onClick?: (utilityType: UtilityType) => void
}

function PerformanceStatusCard(props: PerformanceStatusCardProps) {
  const { isStatusSuccess, utilityType, onClick = noop } = props

  const handleClick = () => {
    onClick(utilityType)
  }

  const classStatus = isStatusSuccess ? 'success' : 'failure'
  const title = utilityType === UtilityType.ELECTRICITY ? 'Electrical' : utilityType

  return (
    <Space
      align='center'
      direction='horizontal'
      className={`${styles.container} bg-system-light-50-dark-100 w-100 m-0.5 p-1`}
      onClick={handleClick}
    >
      <Space className={styles[`icon-${classStatus}`]}>
        <Icon iconName={isStatusSuccess ? 'check-circle' : 'x-circle'} type='solid' size='md' />
      </Space>
      <Space.Compact direction='vertical'>
        <Space>
          <Text className={`system-gray-500-300 ${styles['subtitle-text']}`}>
            {title} Performance
          </Text>
        </Space>
        <Space>
          <Text className='m-semibold general-white-black'>{`${
            isStatusSuccess ? 'On' : 'Off'
          }-target against the benchmark.`}</Text>
        </Space>
      </Space.Compact>
    </Space>
  )
}

export default PerformanceStatusCard
