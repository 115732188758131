import includes from 'lodash/includes'
import { useMemo } from 'react'

import type { SystemsType } from '@/constants/systems'
import { useAppSelector } from '@/redux-store/hooks'
import { getActiveSystemsSelector } from '@/redux-store/thingsboard/telemetry/telemetry.selectors'

export default function useIsSystemActive(system: SystemsType) {
  const activeSystems = useAppSelector(getActiveSystemsSelector)

  const isSystemActive = useMemo(() => includes(activeSystems, system), [activeSystems, system])

  return isSystemActive
}
