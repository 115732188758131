// this file is for keys related to tags, cache keys, etc from rtk

export const LOGIN_MUTATION_FIXED_CACHE_KEY = 'share-login-mutation'

export const AUTH_TAG_KEY = 'AUTH'
export const TELEMETRY_TAG_KEY = 'TELEMETRY'
export const ASSETS_TAGS_KEY = 'ASSETS'

// reporting API
export const FEEDBACK_MODULE_KEY = 'FEEDBACK'
