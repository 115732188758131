import { Skeleton, Space, Typography } from 'antd'
import * as LiveDataIcons from 'components/LiveDataIcons'

import styles from './UtilityLiveVsTargetDataPill.module.scss'

import LiveVsTargetDataPill from '@/components/UtilityLiveVsTargetDataPill/LiveVsTargetDataPill/LiveVsTargetDataPill'
import {
  UtilityType,
  UtilityUnits,
  UtilityUnitsByType,
  UtilityUnitsText,
} from '@/constants/utilities'
import { useAppSelector } from '@/redux-store/hooks'
import { getIsDarkThemeSelector } from '@/redux-store/theme/theme.selectors'
import { getSelectedTimePeriodSelector } from '@/redux-store/thingsboard/telemetry/telemetry.selectors'

const { Text } = Typography

type UtilityLiveVsTargetDataPillPropsType = {
  targetData: number
  liveData?: number
  utilityType: UtilityType
  isLoading: boolean
}

export default function UtilityLiveVsTargetDataPill(props: UtilityLiveVsTargetDataPillPropsType) {
  const { liveData = 0, utilityType, targetData, isLoading } = props
  const selectedTimePeriod = useAppSelector(getSelectedTimePeriodSelector)

  const darkMode = useAppSelector(getIsDarkThemeSelector)

  if (isLoading) return <Skeleton.Input active size='large' />

  let measurement = UtilityUnitsByType[utilityType]
  const utilityTypeText = UtilityUnitsText[utilityType]

  if (utilityType === UtilityType.ELECTRICITY || utilityType === UtilityType.GAS) {
    if (selectedTimePeriod === 'hrs') {
      measurement = UtilityUnits.KW
    } else {
      measurement = UtilityUnits.KWH
    }
  }

  const Icon = LiveDataIcons[`${utilityType}Icon${darkMode ? 'Dark' : 'Light'}`]
  return (
    <div className={`${styles.container} bg-system-light-dark-50`}>
      <Skeleton active={isLoading} loading={isLoading} round>
        <Icon />
        <Space.Compact className={styles['measurement-info']} direction='vertical'>
          <Text className='s-medium general-white-black'>{utilityTypeText}</Text>
          <Text
            className={`xs-medium system-gray-500-200 general-white-black ${styles.measurement}`}
          >
            {measurement}
          </Text>
        </Space.Compact>
        <LiveVsTargetDataPill targetData={targetData} liveData={liveData} />
      </Skeleton>
    </div>
  )
}
