import { Space, Typography } from 'antd'

import Icon from '@/components/Icon/Icon'
import colors from 'styles/colors.module.scss'

const { Text } = Typography

type OverallPerformanceStatusProps = {
  isStatusSuccess: boolean
}

function OverallPerformanceStatus(props: OverallPerformanceStatusProps) {
  const { isStatusSuccess } = props

  return (
    <Space align='center' direction='horizontal'>
      <Icon
        iconName={isStatusSuccess ? 'check-circle' : 'x-circle'}
        color={isStatusSuccess ? colors.skyBlue700 : colors.generalRed}
        type='solid'
        size='md'
      />
      <Text className='l-medium general-white-black'>
        {isStatusSuccess ? 'On-track against the benchmark.' : 'Off-track against the benchmark.'}
      </Text>
    </Space>
  )
}

export default OverallPerformanceStatus
