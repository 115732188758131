import { Image } from 'antd'

import { useAppSelector } from '@/redux-store/hooks'
import { getIsDarkThemeSelector } from '@/redux-store/theme/theme.selectors'

type LogoPropsType = {
  height?: 80 | 64 | 48 | 40 | 32 | 24
  color?: 'black' | 'white' | 'mix'
  logoLength?: 'long' | 'short'
}

export default function Logo(props: LogoPropsType) {
  const { height = 40, color = 'mix', logoLength = 'long' } = props

  const darkMode = useAppSelector(getIsDarkThemeSelector)

  const imageSrc = `/images/logos/${logoLength}-${color}-color-logo-${
    darkMode ? 'dark' : 'light'
  }.png`

  const imgAlt = `${logoLength}-${color}-logo`

  return (
    <Image
      wrapperClassName='d-flex align-center justify-content-center'
      className='object-contain'
      src={imageSrc}
      alt={imgAlt}
      height={height}
      preview={false}
    />
  )
}
