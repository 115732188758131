import { ConfigProvider } from 'antd'
import Test from 'pages/Test'
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import colors from 'styles/colors.module.scss'
import sizes from 'styles/sizes.module.scss'

import { AppLayout } from '@/components'
import { RoutePaths } from '@/constants/routes'
import AuthWrapper from '@/features/authentication/AuthWrapper'
import LogIn from '@/pages/LogIn'
import Summary from '@/pages/Summary'
import Usage from '@/pages/Usage'
import '@/App.scss'
import 'boxicons/css/boxicons.min.css'

const routes = [
  {
    children: [
      {
        children: [
          {
            element: <Summary />,
            path: `/:id/${RoutePaths.SUMMARY}`,
          },
          {
            element: <Usage />,
            path: `/:id/${RoutePaths.USAGE}`,
          },
          {
            element: <Test />,
            path: 'test',
          },
        ],
        element: (
          <AppLayout>
            <Outlet />
          </AppLayout>
        ),
        path: '/',
      },
    ],
  },
  {
    element: <LogIn />,
    path: RoutePaths.LOGIN,
  },
]

const router = createBrowserRouter([
  {
    children: routes,
    element: (
      <AuthWrapper>
        <Outlet />
      </AuthWrapper>
    ),
  },
])

const App = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            controlHeight: 36,
            controlHeightLG: 43,
          },
          Checkbox: {
            colorBorder: colors.systemStroke200,
          },
          Form: {
            colorError: colors.generalBlack,
          },
          Layout: {
            colorBgHeader: 'transparent',
          },
          Select: {
            borderRadius: Number(sizes.mainRadiusNumber),
          },
        },
        token: {
          colorError: colors.generalRed,
          colorLink: colors.skyBlue700,
          colorPrimary: colors.skyBlue500,
          fontFamily: 'Inter',
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  )
}

export default App
