import { useEffect, useRef, useState } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { Spinner } from '@/components'
import { RoutePaths } from '@/constants/routes'
import { LOGIN_MUTATION_FIXED_CACHE_KEY } from '@/constants/rtk-related-keys'
import {
  REFRESH_TOKEN_STORAGE_KEY,
  REMEMBER_ME,
  THEME,
  TOKEN_STORAGE_KEY,
} from '@/constants/storageKeys'
import { useAppDispatch, useAppSelector } from '@/redux-store/hooks'
import { getIsDarkThemeSelector } from '@/redux-store/theme/theme.selectors'
import { setDarkTheme } from '@/redux-store/theme/theme.slice'
import { Theme } from '@/redux-store/theme/types'
import { getCurrentAssetsDataSelector } from '@/redux-store/thingsboard/assets/assets.selectors'
import { assetsApi } from '@/redux-store/thingsboard/assets/assets.service'
import { setAssetsData, setCurrentAsset } from '@/redux-store/thingsboard/assets/assets.slice'
import type { AssetsData } from '@/redux-store/thingsboard/assets/types'
import { getIsLoggedInSelector } from '@/redux-store/thingsboard/authentication/authentication.selectors'
import {
  resetAuthState,
  useLoginMutation,
} from '@/redux-store/thingsboard/authentication/authentication.service'
import { setIsLoggedIn } from '@/redux-store/thingsboard/authentication/authentication.slice'
import { resetTelemetryApi } from '@/redux-store/thingsboard/telemetry/telemetry.service'
import { decodeTokenAndGetTimeDifference, removeUserTokensAndDispatchLogout } from '@/utils/auth'
import { getStorageItem, removeStorageItem, setStorageItem } from '@/utils/storage'

const storageToken = getStorageItem(TOKEN_STORAGE_KEY)

type AuthWrapperPropsType = {
  children: JSX.Element
}

export default function AuthWrapper(props: AuthWrapperPropsType) {
  const { children } = props

  const expTokenTimerId = useRef<NodeJS.Timeout | null>(null)

  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const isLoginPage = pathname === `/${RoutePaths.LOGIN}`
  const [isInitialMount, setIsInitialMount] = useState(true)
  const { id = '' } = useParams()

  const [_login, { data, isLoading }] = useLoginMutation({
    fixedCacheKey: LOGIN_MUTATION_FIXED_CACHE_KEY,
  })
  const { token: dataToken, refreshToken } = data || {}

  const isLoggedIn = useAppSelector(getIsLoggedInSelector)

  const darkMode = useAppSelector(getIsDarkThemeSelector)

  const currentAsset = useAppSelector(getCurrentAssetsDataSelector)

  const resetLoginState = () => {
    dispatch(resetAuthState())
    dispatch(resetTelemetryApi())
    removeUserTokensAndDispatchLogout(dispatch)
  }

  const startResetStateTimerUponHavingAToken = (seconds: number) => {
    expTokenTimerId.current = setTimeout(() => {
      resetLoginState()
    }, seconds * 1000)
  }

  const clearExpTimer = () => {
    if (expTokenTimerId.current) {
      clearTimeout(expTokenTimerId.current)
    }
  }

  async function getUserAssets(token: string) {
    return await dispatch(assetsApi.endpoints.getAssetDataPerUser.initiate(token))
  }

  const dispatchAssetsData = (data: AssetsData[] | undefined) => {
    if (data) {
      dispatch(setAssetsData(data))
      // if on a current page with site selector get the current data from id, if redirect from login page use first asset from the list
      dispatch(setCurrentAsset(id ? data.find((asset) => asset.id.id === id) : data[0]))
    }
  }

  function handleSuccessLogin(token: string, timeDiff: number) {
    getUserAssets(token).then(({ data }) => {
      dispatchAssetsData(data)
      dispatch(setIsLoggedIn(true))
      startResetStateTimerUponHavingAToken(timeDiff)
    })
  }

  useEffect(() => {
    if (storageToken) {
      const timeDifferenceInSeconds = decodeTokenAndGetTimeDifference(storageToken)
      if (timeDifferenceInSeconds <= 0) {
        // Check for refresh token
        const refreshToken = getStorageItem(REFRESH_TOKEN_STORAGE_KEY)

        // if refresh token is not valid logout
        if (decodeTokenAndGetTimeDifference(refreshToken) <= 0) {
          removeUserTokensAndDispatchLogout(dispatch)
        } else {
          // before logging out the user, allow the refresh token request
          const rememberMe = getStorageItem(REMEMBER_ME)
          if (rememberMe) {
            dispatch(setIsLoggedIn(true))
          }
        }
      } else {
        handleSuccessLogin(storageToken, timeDifferenceInSeconds)
      }
    }

    const initialMountTimer: NodeJS.Timeout = setTimeout(() => {
      setIsInitialMount(false)
    }, 1000)

    return () => {
      if (initialMountTimer) {
        clearTimeout(initialMountTimer)
      }
      clearExpTimer()
    }
  }, [])

  useEffect(() => {
    if (dataToken && refreshToken) {
      setStorageItem({ key: TOKEN_STORAGE_KEY, value: dataToken })
      setStorageItem({ key: REFRESH_TOKEN_STORAGE_KEY, value: refreshToken })
      handleSuccessLogin(dataToken, decodeTokenAndGetTimeDifference(dataToken))
    }
  }, [dataToken, refreshToken])

  useEffect(() => {
    if (!isInitialMount && !isLoggedIn) {
      clearExpTimer()
      removeStorageItem(TOKEN_STORAGE_KEY)
      removeStorageItem(REFRESH_TOKEN_STORAGE_KEY)
    }

    // add remember me as default option
    if (isInitialMount && !isLoggedIn) {
      const rememberMe = getStorageItem(REMEMBER_ME)
      if (!rememberMe) {
        setStorageItem({ key: REMEMBER_ME, value: true })
      }
    }
  }, [isInitialMount, isLoggedIn])

  useEffect(() => {
    const currentTheme = getStorageItem(THEME)
    if (!currentTheme) {
      setStorageItem({ key: THEME, value: Theme.LIGHT })
    }
    if (currentTheme === Theme.LIGHT) {
      document.documentElement.setAttribute('data-theme', Theme.LIGHT)
      dispatch(setDarkTheme(false))
    } else {
      document.documentElement.setAttribute('data-theme', Theme.DARK)
      dispatch(setDarkTheme(true))
    }
  }, [darkMode])

  if (isInitialMount || isLoading) return <Spinner />
  if (!isLoggedIn && !isLoginPage) return <Navigate to={`/${RoutePaths.LOGIN}`} replace />
  if (isLoggedIn && isLoginPage && currentAsset)
    return <Navigate to={`/${currentAsset.id.id}/${RoutePaths.SUMMARY}`} replace />

  return children
}
