import type { RootState } from '@/redux-store/store'

export const getTelemetrytateSelector = (state: RootState) => state.telemetry

export const getSelectedTimePeriodSelector = (state: RootState) =>
  getTelemetrytateSelector(state).selectedTimePeriod

export const getActiveUsageTabSelector = (state: RootState) =>
  getTelemetrytateSelector(state).activeUsageTab

export const getActiveSystemsSelector = (state: RootState) =>
  getTelemetrytateSelector(state).activeSystems
