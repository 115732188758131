import { Drawer, Space } from 'antd'
import React from 'react'

import NavigationMenu from '@/components/AppHeader/Menus/NavigationMenu'
import LogoutButton from '@/components/LogoutButton/LogoutButton'
import ThemeSwitch from '@/components/ThemeSwitch/ThemeSwitch'

type ProfileDrawerPropsType = {
  isOpen: boolean
  onClose: () => void
  onLogout: () => void
}

export default function ProfileDrawer(props: ProfileDrawerPropsType) {
  const { isOpen, onClose, onLogout } = props
  return (
    <Drawer
      title='HM Attain'
      placement='left'
      closable={false}
      onClose={onClose}
      open={isOpen}
      bodyStyle={{ padding: 0 }}
      width={300}
    >
      <Space direction='vertical' align='center'>
        <ThemeSwitch />
        <NavigationMenu />
        <LogoutButton onClick={onLogout} />
      </Space>
    </Drawer>
  )
}
