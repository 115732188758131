import { Layout } from 'antd'

import FeedbackIconButton from '../Feedback/FeedbackIcon/FeedbackIconButton'

const { Content } = Layout

type Props = {
  children: string | JSX.Element | JSX.Element[]
}

export default function AppBody(props: Props) {
  const { children } = props
  return (
    <Content className='pb-2'>
      {children}
      <FeedbackIconButton />
    </Content>
  )
}
