import { Space, Typography } from 'antd'
import classNames from 'classnames'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { RoutePaths } from '@/constants/routes'

import styles from './NavigationMenu.module.scss'

const { Text } = Typography

export default function NavigationMenu() {
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const currentPath = location.pathname

  const getHandleRedirectFn = (pathname: string) =>
    function handleRedirect() {
      navigate(`${id}/${pathname}`)
    }

  const getMenuTextClassNames = (pathname: string) =>
    classNames({
      pointer: true,
      's-medium': true,
      [styles['typography-active']]: currentPath !== pathname,
      's-semibold': currentPath === pathname,
    })

  return (
    <Space className={`${styles.container} general-white-black`} size={24}>
      <Text
        className={getMenuTextClassNames(`/${id}/${RoutePaths.SUMMARY}`)}
        onClick={getHandleRedirectFn(RoutePaths.SUMMARY)}
      >
        Summary
      </Text>
      <Text
        className={getMenuTextClassNames(`/${id}/${RoutePaths.USAGE}`)}
        onClick={getHandleRedirectFn(RoutePaths.USAGE)}
      >
        Usage
      </Text>
    </Space>
  )
}
