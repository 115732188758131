import { Button, Space, Typography } from 'antd'
import classNames from 'classnames'
import type { ReactElement } from 'react'
import React from 'react'

type TabItem = {
  label: string
  children?: ReactElement
}

type TabProps = {
  items: TabItem[]
  activeTab: string
  onChange: (label: string) => void
  className?: string
}

const { Text } = Typography

function Tabs(props: TabProps) {
  const { items = [], activeTab = '', onChange, className } = props
  const { label: activeItemLabel, children: activeItemChildren } =
    items.find(({ label }) => label === activeTab) || {}

  const getTabClassNames = (tab: string) =>
    classNames('b-radius-8', {
      'bg-white-gradient btn-shadow': tab === activeItemLabel,
      'tabs-bg': tab !== activeItemLabel,
    })

  const getTextClassName = (tab: string) =>
    classNames({
      's-medium system-gray-700': tab !== activeItemLabel,
      's-semibold': tab === activeItemLabel,
    })

  const getHandleTabClickFn = (label: string) =>
    function handleTabClick() {
      onChange(label)
    }

  return (
    <Space.Compact block direction='vertical' size='large'>
      <Space
        className={`p-0.25 btn-shadow b-radius-12 justify-content-between bg-system-gray-50-dark-100 mx-auto w-100 ${className}`}
        wrap
      >
        {items.map(({ label }) => (
          <Button
            size='small'
            key={label}
            type='default'
            className={getTabClassNames(label)}
            onClick={getHandleTabClickFn(label)}
          >
            <Text className={`${getTextClassName(label)} general-white-black`}>{label}</Text>
          </Button>
        ))}
      </Space>
      {!!activeItemChildren && <Space.Compact block>{activeItemChildren}</Space.Compact>}
    </Space.Compact>
  )
}

export default Tabs
