import { Layout, Space, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './AppFooter.module.scss'

const { Footer } = Layout
const { Text } = Typography

export default function AppFooter() {
  return (
    <Footer className={styles.container}>
      <Text className='xs-regular system-gray-500-300'>
        © 2023 A++ain H|M. All rights reserved.
      </Text>
      <Space>
        {/* TODO: Change links to actual links later */}
        <Link to={'/#'}>
          <Text className='xs-semibold system-gray-500-300'>Terms of Use</Text>
        </Link>
        <Link to={'/#'}>
          <Text className='xs-semibold system-gray-500-300'>Report an Issue</Text>
        </Link>
        <Link to={'/#'}>
          <Text className='xs-semibold system-gray-500-300'>Help</Text>
        </Link>
      </Space>
    </Footer>
  )
}
