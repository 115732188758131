import { Space, Typography } from 'antd'
import classnames from 'classnames'

import { Icon } from '@/components'

import styles from './LiveVsTargetDataPill.module.scss'
const { Text } = Typography

type LiveVsTargetDataPill = {
  liveData: number
  targetData: number
}

const cx = classnames.bind(styles)

export default function LiveVsTargetDataPill(props: LiveVsTargetDataPill) {
  const { liveData, targetData } = props
  const isLiveDataOverTargetData = liveData > targetData

  const liveDataClassname = cx({
    [styles['live-data']]: true,
    [styles['warning']]: isLiveDataOverTargetData,
  })
  return (
    <Space.Compact className={`${styles['live-data-container']} pill-shadow`}>
      <div className={liveDataClassname}>
        <Text className='xs-semibold general-white-black'>{liveData}</Text>
      </div>
      <Space size={5} className={styles['target-data']}>
        <Icon iconName='target-lock' size='xs' type='regular' className='general-white-black' />
        <Text className='xs-regular general-white-black'>{targetData}</Text>
      </Space>
    </Space.Compact>
  )
}
