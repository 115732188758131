import { Header } from 'antd/es/layout/layout'

import { Logo } from '@/components'

export default function UnauthorizedHeader() {
  return (
    <Header className='nav-shadow d-flex align-center h-auto py-1 px-2'>
      <Logo color='mix' logoLength='long' />
    </Header>
  )
}
