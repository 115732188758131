import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { SystemsType } from '@/constants/systems'
import type { TimePeriodKeysType } from '@/constants/utilities'
import { UtilityType } from '@/constants/utilities'

export interface TelemetryState {
  activeSystems: SystemsType[]
  selectedTimePeriod: TimePeriodKeysType
  activeUsageTab: UtilityType
}

const initialState: TelemetryState = {
  activeSystems: [],
  activeUsageTab: UtilityType.ELECTRICITY,
  selectedTimePeriod: 'hrs',
}

export const telemetrySlice = createSlice({
  initialState,
  name: 'telemetry',
  reducers: {
    setActiveSystems: (state, action: PayloadAction<SystemsType[]>) => {
      state.activeSystems = action.payload
    },
    setActiveUsageTab: (state, action: PayloadAction<UtilityType>) => {
      state.activeUsageTab = action.payload
    },
    setTimePeriod: (state, action: PayloadAction<TimePeriodKeysType>) => {
      state.selectedTimePeriod = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setActiveSystems, setTimePeriod, setActiveUsageTab } = telemetrySlice.actions

export default telemetrySlice.reducer
