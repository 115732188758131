import { Button, Space, Typography } from 'antd'
import map from 'lodash/map'
import values from 'lodash/values'
import { useNavigate, useParams } from 'react-router-dom'

import Icon from '@/components/Icon/Icon'
import AlertCard from '@/components/PerformanceStatusList/AlertCard/AlertCard'
import PerformanceStatusCard from '@/components/PerformanceStatusList/PerformanceStatusCard/PerformanceStatusCard'
import { RoutePaths } from '@/constants/routes'
import { UtilityType } from '@/constants/utilities'
import { useAppDispatch } from '@/redux-store/hooks'
import { setActiveUsageTab } from '@/redux-store/thingsboard/telemetry/telemetry.slice'
import type { AlertPanelsResponseType } from '@/redux-store/thingsboard/telemetry/types'

const { Text } = Typography

type PerformanceStatusListPropsType = {
  isElectricityOnTrack: boolean
  isWaterOnTrack: boolean
  isGasOnTrack: boolean
  showGasData: boolean
  showWaterData: boolean
  showElectricityData: boolean
  alertPanels?: AlertPanelsResponseType
}

function PerformanceStatusList(props: PerformanceStatusListPropsType) {
  const {
    isElectricityOnTrack,
    isGasOnTrack,
    isWaterOnTrack,
    showElectricityData,
    showGasData,
    showWaterData,
    alertPanels,
  } = props
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const handleOnClickViewAll = () => {
    navigate(`../${id}/${RoutePaths.USAGE}`)
  }

  const handleCardClick = (utilityType: UtilityType) => {
    dispatch(setActiveUsageTab(utilityType))
    handleOnClickViewAll()
  }

  return (
    <Space direction='vertical' className='w-100'>
      <Space className='w-100 mt-0 mb-0 ml-0.5 mr-0.5 justify-content-between'>
        <h3 className='mb-0 general-white-black'>Usage</h3>
        <Button
          type='default'
          onClick={handleOnClickViewAll}
          className='btn-shadow b-radius-8 d-flex align-center bg-white-gradient'
        >
          <Text className='s-semibold mr-0.5 general-white-black'>View All</Text>
          <Icon iconName='bar-chart-alt-2' type='solid' size='xs' className='general-white-black' />
        </Button>
      </Space>
      <Space direction='vertical' className='w-100'>
        {showElectricityData && (
          <PerformanceStatusCard
            isStatusSuccess={isElectricityOnTrack}
            utilityType={UtilityType.ELECTRICITY}
            onClick={handleCardClick}
          />
        )}
        {showGasData && (
          <PerformanceStatusCard
            isStatusSuccess={isGasOnTrack}
            utilityType={UtilityType.GAS}
            onClick={handleCardClick}
          />
        )}
        {showWaterData && (
          <PerformanceStatusCard
            isStatusSuccess={isWaterOnTrack}
            utilityType={UtilityType.WATER}
            onClick={handleCardClick}
          />
        )}
      </Space>
      <Space direction='vertical' className='w-100'>
        {map(values(alertPanels), ({ active, text }) => active && <AlertCard text={text} />)}
      </Space>
    </Space>
  )
}

export default PerformanceStatusList
