import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { ASSETS_TAGS_KEY, AUTH_TAG_KEY, TELEMETRY_TAG_KEY } from '@/constants/rtk-related-keys'
import { THINGS_BOARD_BASE_URL } from '@/constants/urls'
import { fetchFn } from '@/utils'
import { baseQueryWithReauth } from '@/utils/auth'

const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${THINGS_BOARD_BASE_URL}/`,
  fetchFn,
})

export const thingsBoardApi = createApi({
  baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, baseQuery),
  endpoints: () => ({}),
  reducerPath: 'thingsBoardApi',
  tagTypes: [AUTH_TAG_KEY, TELEMETRY_TAG_KEY, ASSETS_TAGS_KEY],
})
