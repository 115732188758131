import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { FEEDBACK_MODULE_KEY } from '@/constants/rtk-related-keys'
import { fetchFn } from '@/utils'

export const reportingApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_REPORTING_BASE_URL}/`,
    fetchFn,
  }),
  endpoints: () => ({}),
  reducerPath: 'reportingApi',
  tagTypes: [FEEDBACK_MODULE_KEY],
})
