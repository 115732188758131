import { Col, Divider, Row, Skeleton, Space } from 'antd'

import InfoContainer from '@/components/SystemConsumptionList/SystemConsumptionCard/InfoContainer/InfoContainer'
import StatsContainer from '@/components/SystemConsumptionList/SystemConsumptionCard/StatsContainer/StatsContainer'
import type { SystemConsumptionCardPropsType } from '@/components/SystemConsumptionList/SystemConsumptionCard/types'
import type { SystemsType } from '@/constants/systems'
import { useIsSystemActive, useScreenSize } from '@/hooks'

export default function SystemConsumptionCard(props: SystemConsumptionCardPropsType) {
  const {
    isLoading,
    title,
    subtitle = 'FDD & PLM',
    numOfInefficiencies,
    numOfFaults,
    numOfItems,
    usage,
    onClickDetails,
  } = props

  const { isScreenDesktop } = useScreenSize()
  const isSystemActive = useIsSystemActive(title as SystemsType)

  if (!isSystemActive) return null

  return (
    <Space.Compact block className='bg-general-white-dark-100 b-radius-8 input-shadow'>
      <Row className='w-100' justify='space-between'>
        <Col xs={24} lg={8} className='p-0.75'>
          <Skeleton active={isLoading} loading={isLoading} round>
            <InfoContainer
              title={title}
              subtitle={subtitle}
              numOfInefficiencies={numOfInefficiencies}
              numOfFaults={numOfFaults}
              numOfItems={numOfItems}
              onClickDetails={onClickDetails}
            />
          </Skeleton>
        </Col>
        <Col xs={24} lg={1} className='bg-system-white-gray-800'>
          <Divider
            type={isScreenDesktop ? 'vertical' : 'horizontal'}
            className='h-100 m-0 bg-system-gray-50-800'
          />
        </Col>
        <Col xs={24} lg={15} className='d-flex'>
          <Skeleton active={isLoading} loading={isLoading} round className='p-0.75'>
            <StatsContainer energy={usage.energy} carbon={usage.carbon} fiscal={usage.fiscal} />
          </Skeleton>
        </Col>
      </Row>
    </Space.Compact>
  )
}
