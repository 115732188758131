import { Column } from '@ant-design/charts'
import { Space, Typography } from 'antd'
import colors from 'styles/colors.module.scss'

import { THEME } from '@/constants/storageKeys'
import { UtilityType } from '@/constants/utilities'
import { Theme } from '@/redux-store/theme/types'
import type { UsageChartDataFormat } from '@/redux-store/thingsboard/telemetry/types'
import { getChartConfig } from '@/utils'
import { getStorageItem } from '@/utils/storage'

const { Title, Text } = Typography

const fieldName = 'type'
const getColumnColor = ({ [fieldName]: type }: Record<string, any>) => {
  const currentTheme = getStorageItem(THEME)

  switch (type) {
    case `${UtilityType.ELECTRICITY} - Actual`:
      return colors.skyBlue500
    case `${UtilityType.ELECTRICITY} - Benchmark`:
      return currentTheme === Theme.LIGHT ? colors.systemGray200 : colors.generalWhite
    case `${UtilityType.GAS} - Actual`:
      return colors.generalRed
    case `${UtilityType.GAS} - Benchmark`:
      return colors.redLight
    default:
      return colors.skyBlue500
  }
}

const columnConfig = getChartConfig({ fieldName, getColor: getColumnColor })

type PerformanceChartPropsType = {
  data: Array<UsageChartDataFormat>
  unitMeasurement: string
  darkMode: boolean
}

export default function PerformanceChart(props: PerformanceChartPropsType) {
  const { data, unitMeasurement } = props

  return (
    <Space
      direction='vertical'
      className='w-100 b-radius-12 input-shadow pt-1 pb-1 pl-1 bg-system-light-50-dark-100'
      prefixCls='w-100'
      size={40}
    >
      <Space className='justify-content-between w-100 pr-2'>
        <Space size='small' className='align-center'>
          <Title level={5} className='m-0 general-white-black'>
            Overall Performance
          </Title>
          <Text className='m-0 xs-medium general-white-black'>{unitMeasurement}</Text>
        </Space>
      </Space>
      <Column {...columnConfig} data={data} />
    </Space>
  )
}
