import { Typography } from 'antd'

import { Icon } from '@/components'
import colors from 'styles/colors.module.scss'

const { Text } = Typography

type InputErrorMessagePropsType = {
  message: string
}

export default function InputErrorMessage(props: InputErrorMessagePropsType) {
  const { message } = props
  return (
    <div className='d-flex align-center pt-0.5'>
      <Icon iconName='error' size='xs' type='solid' color={colors.generalRed} />
      <Text className='xs-regular pl-0.5'>{message}</Text>
    </div>
  )
}
