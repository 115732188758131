import { Col, Row, Space, Typography } from 'antd'
import map from 'lodash/map'
import pickBy from 'lodash/pickBy'
import size from 'lodash/size'
import values from 'lodash/values'
import moment from 'moment'
import React, { useMemo } from 'react'

import type { SystemFddStateRuleType } from '@/redux-store/thingsboard/telemetry/types'
import { capitalizeWordsAndReplaceUnderscores } from '@/utils/stringUtils'

const { Text } = Typography

type FddTablePropsType = {
  faults: SystemFddStateRuleType[]
}

function FddStateTable(props: FddTablePropsType) {
  const { faults } = props
  const getFaults = useMemo(() => pickBy(faults, ({ type }) => type === 'f'), [faults])
  const getInefficiencies = useMemo(() => pickBy(faults, ({ type }) => type === 'i'), [faults])

  return (
    <>
      <Text className='l-semibold general-white-black'>Faults</Text>
      <FddTableRows data={values(getFaults)} />
      <Text className='l-semibold general-white-black mt-1'>Inefficiencies</Text>
      <FddTableRows data={values(getInefficiencies)} />
    </>
  )
}

type FddTableRowsPropsType = {
  data: SystemFddStateRuleType[]
}

function FddTableRows(props: FddTableRowsPropsType) {
  const { data } = props
  return (
    <>
      {size(data) ? (
        map(data, ({ description, timestamp }) => (
          <div className='bt-light mt-1 mb-1.5 ml-0.5' key={timestamp}>
            <Row className='mb-0.25 mt-0.5'>
              <Col span={12}>
                <Space.Compact block direction='vertical'>
                  <Text className='xs-semibold system-gray-700-300'>Time</Text>
                  <Text className='s-regular general-white-black mt-0.5'>
                    {moment(timestamp).format('ddd, MMMM D YYYY HH:mm')}
                  </Text>
                </Space.Compact>
              </Col>
              <Col span={12}>
                <Space.Compact block direction='vertical'>
                  <Text className='xs-semibold system-gray-700-300'>Description</Text>
                  <Text className='s-regular general-white-black mt-0.5'>
                    {capitalizeWordsAndReplaceUnderscores(description)}
                  </Text>
                </Space.Compact>
              </Col>
            </Row>
          </div>
        ))
      ) : (
        <Text className='m-semibold system-gray-500-300 mt-1 mb-1'>No results</Text>
      )}
    </>
  )
}

export default FddStateTable
