import { Drawer, Space } from 'antd'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import SiteCard from '@/components/SiteCard/SiteCard'
import { RoutePaths } from '@/constants/routes'
import { useScreenSize } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/redux-store/hooks'
import { getAssetsDataSelector } from '@/redux-store/thingsboard/assets/assets.selectors'
import { setCurrentAsset } from '@/redux-store/thingsboard/assets/assets.slice'
import type { AssetsData } from '@/redux-store/thingsboard/assets/types'

type SiteDrawerPropsType = {
  isOpen: boolean
  onClose: () => void
}

function SiteDrawer(props: SiteDrawerPropsType) {
  const { isOpen, onClose } = props
  const { isScreenMobile } = useScreenSize()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const pathname = useLocation().pathname

  const assetsData = useAppSelector(getAssetsDataSelector)

  const drawerWidth = isScreenMobile ? 300 : 400

  function showAssetInfo(assetData: AssetsData) {
    if (pathname.includes(assetData.id.id)) {
      return
    }

    dispatch(setCurrentAsset(assetData))
    onClose()

    if (pathname.includes(RoutePaths.SUMMARY)) {
      navigate(`../${assetData.id.id}/${RoutePaths.SUMMARY}`)
    }

    if (pathname.includes(RoutePaths.USAGE)) {
      navigate(`../${assetData.id.id}/${RoutePaths.USAGE}`)
    }
  }

  return (
    <Drawer
      onClose={onClose}
      open={isOpen}
      placement='left'
      closable={false}
      width={drawerWidth}
      className='mt-4'
    >
      <Space direction='vertical' size='middle' className='w-100'>
        {assetsData.map((item) => (
          <SiteCard key={item.id.id} name={item.name} onClick={() => showAssetInfo(item)} />
        ))}
      </Space>
    </Drawer>
  )
}

export default SiteDrawer
