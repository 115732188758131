import { Layout } from 'antd'

import AppBaseConfig from '@/components/AppBaseConfig/AppBaseConfig'
import AppBody from '@/components/AppBody/AppBody'
import AppFooter from '@/components/AppFooter/AppFooter'
import AppHeader from '@/components/AppHeader/AppHeader'

type Props = {
  children: string | JSX.Element | JSX.Element[]
}

export default function AppBaseLayout(props: Props) {
  const { children } = props

  return (
    <AppBaseConfig>
      <Layout className='h-min-100'>
        <AppHeader />
        <AppBody children={children} />
        <AppFooter />
      </Layout>
    </AppBaseConfig>
  )
}
