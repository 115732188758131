import { tooltipContentFormater } from '@/utils/tooltipContentFormatter'
import colors from 'styles/colors.module.scss'
import sizes from 'styles/sizes.module.scss'

// Define the chart configuration function
type GetChartConfigParamsType = {
  fieldName: string
  getColor: ({}: Record<string, any>) => string
}

export function getChartConfig(params: GetChartConfigParamsType) {
  const { fieldName, getColor } = params
  return {
    color: getColor,
    colorField: fieldName,
    columnStyle: {
      radius: [sizes.smallRadiusNumber, sizes.smallRadiusNumber, 0, 0],
    },
    interactions: [
      {
        enable: false,
        type: 'active-region',
      },
    ],
    isGroup: true,
    label: false as const,
    legend: false as const,
    seriesField: fieldName,
    style: { backgroundColor: 'transparent' },
    theme: {
      fontFamily: 'Poppins',
      innerLabels: false,
    },
    tooltip: {
      customContent: tooltipContentFormater,
      domStyles: {
        'g2-tooltip': {
          backgroundColor: colors.systemDarkGray,
          color: colors.systemGray100,
        },
      },
      enterable: true,
      showMarkers: false,
    },
    xField: 'timeFrame',
    yField: 'value',
  }
}
