import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function CarbonIcon(props: DimensionProps) {
  const { width = '49', height = '48' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_1094_2461)'>
        <rect x='4' y='3' width='40' height='40' rx='20' fill='url(#paint0_linear_1094_2461)' />
        <path
          d='M35.6668 12.9784L35.5268 11.5084L34.1268 11.975C31.7274 12.8087 29.1653 13.0645 26.6484 12.7217C24.0038 12.3113 21.2969 12.676 18.8551 13.7717C17.5607 14.3229 16.4247 15.1891 15.5505 16.2915C14.6763 17.3938 14.0918 18.6974 13.8501 20.0834C13.5367 21.8738 13.6935 23.715 14.3051 25.4267C14.6989 24.5378 15.1591 23.6798 15.6818 22.86C16.8673 21.1157 18.5101 19.7312 20.4301 18.8584C22.2798 18.0138 24.3016 17.6143 26.3334 17.6917C24.3991 17.9874 22.5476 18.6828 20.8968 19.7334C20.1005 20.2337 19.3713 20.8335 18.7268 21.5184C18.1104 22.2029 17.5669 22.9497 17.1051 23.7467C16.2377 25.4127 15.6205 27.1974 15.2734 29.0434C14.8993 30.8953 14.6962 32.7776 14.6668 34.6667H17.0001C17.1098 32.9725 17.3398 31.2883 17.6884 29.6267C19.3071 30.5008 21.1224 30.9465 22.9618 30.9217C24.6773 30.9205 26.3752 30.5754 27.9551 29.9067C36.8334 26.115 35.6668 13.5034 35.6668 12.9784Z'
          fill='url(#paint1_linear_1094_2461)'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_1094_2461'
          x='0'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_1094_2461'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1094_2461' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_1094_2461'
            result='effect2_dropShadow_1094_2461'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_1094_2461'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1094_2461'
          x1='24'
          y1='3'
          x2='24'
          y2='43'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D4D4D' />
          <stop offset='1' stopColor='#343434' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1094_2461'
          x1='24.7051'
          y1='11.5084'
          x2='24.7051'
          y2='34.6667'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A2FF59' />
          <stop offset='1' stopColor='#1EC6FB' />
        </linearGradient>
      </defs>
    </svg>
  )
}
