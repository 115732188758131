import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function ElectricityIcon(props: DimensionProps) {
  const { width = '49', height = '48' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 49 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_23_289)'>
        <rect x='4.37598' y='3' width='40' height='40' rx='20' fill='white' />
        <path
          d='M23.2093 26.5003H17.376L25.5426 10.167V19.5003H31.376L23.2093 35.8337V26.5003Z'
          fill='url(#paint0_linear_23_289)'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_23_289'
          x='0.375977'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_23_289'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_23_289' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_23_289'
            result='effect2_dropShadow_23_289'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_23_289'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_23_289'
          x1='24.376'
          y1='10.167'
          x2='24.376'
          y2='35.8337'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FA00FF' />
          <stop offset='1' stopColor='#0094FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}
