enum MonthAbbreviation {
  Jan = 'January',
  Feb = 'February',
  Mar = 'March',
  Apr = 'April',
  May = 'May',
  Jun = 'June',
  Jul = 'July',
  Aug = 'August',
  Sep = 'September',
  Oct = 'October',
  Nov = 'November',
  Dec = 'December',
}

enum DayAbbreviation {
  Sun = 'Sunday',
  Mon = 'Monday',
  Tue = 'Tuesday',
  Wed = 'Wednesday',
  Thu = 'Thursday',
  Fri = 'Friday',
  Sat = 'Saturday',
}

export function getFullNameFromAbbreviation(abbreviation: string): string {
  const lookupTable = { ...MonthAbbreviation, ...DayAbbreviation }
  return lookupTable[abbreviation as keyof typeof lookupTable] || abbreviation
}
