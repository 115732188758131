import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import capitalize from 'lodash/capitalize'
import round from 'lodash/round'

import type { TimePeriodKeysType, UtilitiesKeyType, UtilityType } from '@/constants/utilities'
import type {
  RunningUsageChartDataByTimeType,
  RunningUsageValueResponseType,
  RunningUsageValueResultType,
  UsageChartDataFormat,
} from '@/redux-store/thingsboard/telemetry/types'

dayjs.extend(weekOfYear)
dayjs.extend(advancedFormat)

type GenerateUsageChartDataFormatObjectParamsType = {
  benchmark: number
  idx: number
  arrLength: number
  timePeriod: TimePeriodKeysType
  name: UtilityType
}

type CreateUsageChartDataObjectParamsType = Pick<
  GenerateUsageChartDataFormatObjectParamsType,
  'benchmark' | 'name'
> & {
  timeFrame: string
  isActual: boolean
  isOverBenchmark?: boolean
}

export const createUsageChartDataObject = (params: CreateUsageChartDataObjectParamsType) => {
  const { timeFrame, benchmark, name, isActual, isOverBenchmark } = params
  let typePostFix = isActual ? 'Actual' : 'Benchmark'
  if (isOverBenchmark) {
    typePostFix = 'Over'
  }
  return {
    timeFrame,
    type: `${name} - ${typePostFix}`,
    value: benchmark,
  }
}

const subtractTimeAndCreateUsageChartDataObject = (
  params: GenerateUsageChartDataFormatObjectParamsType
) => {
  const { arrLength, benchmark, idx, timePeriod, name } = params
  const timeToSubtract = arrLength - idx
  let timeFrame = ''
  switch (timePeriod) {
    case 'hrs':
      timeFrame = dayjs().subtract(timeToSubtract, 'h').format('H')
      break
    case 'mth':
      timeFrame = dayjs().subtract(timeToSubtract, 'M').format('MMM')
      break
    case 'wek':
      timeFrame = dayjs().subtract(timeToSubtract, 'w').format('ww')
      break
    case 'day':
      timeFrame = dayjs().subtract(timeToSubtract, 'd').format('ddd')
      break
  }
  return createUsageChartDataObject({ benchmark, isActual: true, name, timeFrame })
}

function getMapFn(timePeriod: TimePeriodKeysType, name: UtilityType) {
  return function mapFn(benchmark: number, idx: number, arr: number[]) {
    return subtractTimeAndCreateUsageChartDataObject({
      arrLength: arr.length,
      benchmark,
      idx,
      name,
      timePeriod,
    })
  }
}

export const transformRunningUsageValue = (usageValue: RunningUsageValueResponseType) => {
  const runningUsageEntries = Object.entries(usageValue)
  const transformedRunningUsageValue = runningUsageEntries.reduce((acc, [key, utilityValues]) => {
    // example: 'primary_gas' or 'catering' => 'Gas' or 'Catering'
    const shortUtilName = key.split('_').slice(-1)[0]
    const capitalizedShortUtilName = capitalize(shortUtilName) as UtilityType
    acc[key as UtilitiesKeyType] = {
      day: utilityValues.day?.map(getMapFn('day', capitalizedShortUtilName)) ?? [],
      hrs: utilityValues.hrs?.map(getMapFn('hrs', capitalizedShortUtilName)) ?? [],
      mth: utilityValues.mth?.map(getMapFn('mth', capitalizedShortUtilName)) ?? [],
      wek: utilityValues.wek?.map(getMapFn('wek', capitalizedShortUtilName)) ?? [],
    }

    return acc
  }, {} as RunningUsageValueResultType)
  return transformedRunningUsageValue
}

export const getLatestUsageValue = (
  usageChartData: RunningUsageChartDataByTimeType | undefined,
  selectedTimePeriod: TimePeriodKeysType
) => {
  if (!usageChartData) return 0
  return usageChartData[selectedTimePeriod].slice(-1)[0].value || 0
}

type CreateTargetUsageChartDataParamsType = {
  runningUsageData: UsageChartDataFormat[]
  targetBenchmark: Array<number>
  utilityType: UtilityType
}

export const createTargetUsageChartData = ({
  runningUsageData,
  targetBenchmark,
  utilityType,
}: CreateTargetUsageChartDataParamsType) => {
  const targetUsageChartData: UsageChartDataFormat[] = []
  runningUsageData.forEach(({ timeFrame }, index) => {
    targetUsageChartData.push(
      createUsageChartDataObject({
        benchmark: round(targetBenchmark[index], 2) ?? 0,
        isActual: false,
        isOverBenchmark: false,
        name: utilityType,
        timeFrame,
      })
    )
  })
  return targetUsageChartData
}
