import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function FeedbackIcon(props: DimensionProps) {
  const { width = '36', height = '36' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_2248_6191)'>
        <path
          d='M33.9212 12.7689C33.7277 12.2019 33.2162 11.8014 32.6192 11.7549L24.0677 11.0754L20.3672 2.88387C20.1257 2.34537 19.5902 2.00037 19.0007 2.00037C18.4112 2.00037 17.8757 2.34537 17.6342 2.88237L13.9337 11.0754L5.3822 11.7549C4.7957 11.8014 4.2902 12.1869 4.0907 12.7404C3.8912 13.2939 4.0322 13.9134 4.4537 14.3244L10.7732 20.4849L8.5382 30.1629C8.4002 30.7614 8.6417 31.3839 9.1472 31.7334C9.4052 31.9104 9.7022 32.0004 10.0007 32.0004C10.2902 32.0004 10.5812 31.9164 10.8332 31.7484L19.0007 26.3034L27.1682 31.7484C27.6902 32.0964 28.3757 32.0829 28.8857 31.7124C29.3927 31.3419 29.6162 30.6924 29.4437 30.0894L26.7002 20.4894L33.5042 14.3664C33.9497 13.9644 34.1132 13.3374 33.9212 12.7689Z'
          fill='url(#paint0_linear_2248_6191)'
        />
        <path
          d='M32.5796 12.2533L32.5804 12.2534C32.9759 12.2842 33.3177 12.5499 33.4477 12.9294C33.5756 13.3089 33.4665 13.7267 33.1695 13.9949C33.1694 13.995 33.1693 13.9951 33.1692 13.9952L26.3657 20.1177L26.1337 20.3266L26.2194 20.6268L28.9629 30.2268L28.963 30.2269C29.0776 30.6277 28.929 31.0611 28.5911 31.3084C28.2501 31.5556 27.7929 31.5639 27.4455 31.3323L19.278 25.8873L19.0007 25.7024L18.7233 25.8873L10.5558 31.3323C10.3879 31.4443 10.1939 31.5004 10.0007 31.5004C9.80364 31.5004 9.60554 31.4412 9.43087 31.3216C9.09411 31.0884 8.93357 30.6736 9.02542 30.2752L11.2604 20.5974L11.3237 20.3232L11.1222 20.1268L4.80277 13.9664C4.52229 13.6929 4.42773 13.2799 4.56108 12.9099C4.69393 12.5413 5.03076 12.2843 5.42172 12.2533L5.42181 12.2533L13.9733 11.5738L14.2678 11.5504L14.3894 11.2812L18.0899 3.08818L18.0902 3.08744C18.2506 2.73076 18.6071 2.50037 19.0007 2.50037C19.3941 2.50037 19.7504 2.73052 19.911 3.08847L19.9115 3.08971L23.612 11.2812L23.7336 11.5504L24.0281 11.5738L32.5796 12.2533Z'
          stroke='url(#paint1_linear_2248_6191)'
          stroke-opacity='0.4'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_2248_6191'
          x='0.00146532'
          y='0.000366449'
          width='37.999'
          height='38'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.327039 0 0 0 0 0.0209028 0 0 0 0 0.716667 0 0 0 0.32 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2248_6191' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2248_6191'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_2248_6191'
          x1='19.001'
          y1='-1.46446'
          x2='19.001'
          y2='40.1091'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FF49C1' />
          <stop offset='1' stop-color='#0D6CFC' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2248_6191'
          x1='19.001'
          y1='2.00037'
          x2='19.001'
          y2='32.0004'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-opacity='0.17' />
          <stop offset='1' stop-color='#1F1D77' />
        </linearGradient>
      </defs>
    </svg>
  )
}
