import { Col, Row, Space, Typography } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
  FullScreenButton,
  OverallPerformanceStatus,
  PerformanceStatusList,
  TimePeriodSelect,
} from '@/components'
import { POLLING_INTERVAL_IN_MS, UtilityType, UtilityUnitsByType } from '@/constants/utilities'
import PerformanceChart from '@/features/summary/components/PerformanceChart/PerformanceChart'
import { useScreenSize } from '@/hooks'
import { useAppSelector } from '@/redux-store/hooks'
import { getIsDarkThemeSelector } from '@/redux-store/theme/theme.selectors'
import { getSelectedTimePeriodSelector } from '@/redux-store/thingsboard/telemetry/telemetry.selectors'
import {
  useGetAlertPanelsQuery,
  useGetBenchmarkValuesQuery,
  useGetSummaryTelemetryQuery,
} from '@/redux-store/thingsboard/telemetry/telemetry.service'
import type { UsageChartDataFormat } from '@/redux-store/thingsboard/telemetry/types'
import { createTargetUsageChartData } from '@/utils'

const { Title } = Typography

function SummaryContainer() {
  const { id = '' } = useParams()
  const darkMode = useAppSelector(getIsDarkThemeSelector)
  const { currentData } = useGetSummaryTelemetryQuery(id, {
    pollingInterval: POLLING_INTERVAL_IN_MS,
    skip: !id,
  })

  const { data: { alertPanels } = {} } = useGetAlertPanelsQuery(id, {
    pollingInterval: POLLING_INTERVAL_IN_MS,
    skip: !id,
  })

  const { isScreenMobile, isScreenDesktop } = useScreenSize()

  const selectedTimePeriod = useAppSelector(getSelectedTimePeriodSelector)

  const { runningUsage, onTrack } = currentData || {}

  const { currentData: { benchmarkValues } = {} } = useGetBenchmarkValuesQuery(id, {
    pollingInterval: POLLING_INTERVAL_IN_MS,
    skip: !id,
  })

  const { elec: targetElectricity, gas: targetGas, water: targetWater } = benchmarkValues || {}

  const isElectricityOnTrack = onTrack?.primary_electricity[selectedTimePeriod] || false
  const isGasOnTrack = onTrack?.primary_gas[selectedTimePeriod] || false
  const isWaterOnTrack = onTrack?.primary_water[selectedTimePeriod] || false

  const isEverythingOnTrack = isElectricityOnTrack && isWaterOnTrack && isGasOnTrack
  const showGasData = !isEmpty(targetGas)
  const showElectricityData = !isEmpty(targetElectricity)
  const showWaterData = !isEmpty(targetWater)

  const chartData = useMemo(() => {
    let newChartData: UsageChartDataFormat[] = []
    if (!runningUsage) return newChartData
    const { primary_electricity, primary_gas } = runningUsage
    if (showElectricityData) {
      const primaryElectricityForGivenTimePeriod = primary_electricity[selectedTimePeriod]
      const newElectricityRunningUsageChartData = createTargetUsageChartData({
        runningUsageData: primaryElectricityForGivenTimePeriod,
        targetBenchmark: targetElectricity[selectedTimePeriod],
        utilityType: UtilityType.ELECTRICITY,
      })
      newChartData = newChartData.concat(
        primaryElectricityForGivenTimePeriod,
        newElectricityRunningUsageChartData
      )
    }
    if (showGasData) {
      const primaryGasForGivenTimePeriod = primary_gas[selectedTimePeriod]
      const newGasRunningUsageChartData = createTargetUsageChartData({
        runningUsageData: primaryGasForGivenTimePeriod,
        targetBenchmark: targetGas[selectedTimePeriod],
        utilityType: UtilityType.GAS,
      })
      newChartData = newChartData.concat(primaryGasForGivenTimePeriod, newGasRunningUsageChartData)
    }

    return newChartData
  }, [
    runningUsage,
    targetElectricity,
    targetGas,
    showElectricityData,
    showGasData,
    selectedTimePeriod,
  ])

  const unitMeasurement = UtilityUnitsByType[UtilityType.ELECTRICITY]

  return (
    <Space.Compact
      direction='vertical'
      className={`w-100 pt-2 ${isScreenMobile ? 'pl-1 pr-1' : 'pl-6 pr-6'}`}
    >
      <Row>
        <Col span={24}>
          <Row>
            <Col className='d-flex justify-content-start' xs={24} lg={12}>
              <Title className='general-white-black'>Summary</Title>
            </Col>
            {isScreenDesktop && (
              <Col lg={12} className={'d-flex justify-content-end'}>
                <FullScreenButton />
              </Col>
            )}
          </Row>
        </Col>
        <Col sm={24} lg={12} className='pr-1.5'>
          <Row align='middle' justify='space-between' gutter={[16, 32]}>
            <Col>
              <OverallPerformanceStatus isStatusSuccess={isEverythingOnTrack} />
            </Col>
            <Col>
              <TimePeriodSelect />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='justify-content-center pt-2' gutter={48}>
        <Col lg={12} span={24}>
          <PerformanceChart
            data={chartData}
            unitMeasurement={unitMeasurement}
            darkMode={darkMode}
          />
        </Col>
        <Col lg={12} span={24} className={!isScreenDesktop ? 'pt-4' : 'pt-0'}>
          <PerformanceStatusList
            isElectricityOnTrack={isElectricityOnTrack}
            isWaterOnTrack={isWaterOnTrack}
            isGasOnTrack={isGasOnTrack}
            showGasData={showGasData}
            showWaterData={showWaterData}
            showElectricityData={showElectricityData}
            alertPanels={alertPanels}
          />
        </Col>
      </Row>
    </Space.Compact>
  )
}

export default SummaryContainer
