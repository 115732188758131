import { Button, Card, Space, Typography } from 'antd'
import { useState } from 'react'

import styles from '../Feedback.module.scss'

import FeedbackIcon from '@/components/Feedback/FeedbackIcon/FeedbackIcon'
import FeedbackModal from '@/components/Feedback/FeedbackModal/FeedbackModal'
import FeedbackSuccessModal from '@/components/Feedback/FeedbackSuccessModal/FeedbackSuccessModal'
import { HIDE_FEEDBACK } from '@/constants/storageKeys'
import { useSendFeedbackMutation } from '@/redux-store/reporting/feedback/feedback.service'
import type { FeedbackDTO } from '@/redux-store/reporting/feedback/types'
import { getStorageItem, setStorageItem } from '@/utils/storage'

const { Text } = Typography

export default function FeedbackIconButton() {
  const [hideFeedback, setHideFeedback] = useState(getStorageItem(HIDE_FEEDBACK))
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [sendFeedback, { isSuccess: isSendFeedbackSuccessful }] = useSendFeedbackMutation()

  function handleInfoMessage() {
    setStorageItem({ key: HIDE_FEEDBACK, value: true })
    setHideFeedback(true)
  }

  function handleOpenFeedbackForm() {
    handleInfoMessage()
    setOpenFeedbackModal(true)
  }

  function closeFeedBackModal() {
    setOpenFeedbackModal(false)
  }

  async function handleSubmit(data: FeedbackDTO) {
    const formData = { ...data } as FeedbackDTO
    await sendFeedback(formData)
    setOpenFeedbackModal(false)
    setOpenSuccessModal(true)
  }

  return (
    <Space className={`${styles.container}`}>
      <Text
        className={`${styles['icon-container']} fixed r-25 b-50 bg-system-light-50-dark-100  pointer input-shadow b-radius-12`}
        onClick={handleOpenFeedbackForm}
      >
        <FeedbackIcon />
      </Text>
      {!hideFeedback && (
        <Card
          bordered={false}
          className={`${styles['feedback-info']} bg-system-light-50-dark-100 input-shadow b-radius-12`}
        >
          <h4 className='dark-blue'>Feedback and complaints are now simpler.</h4>
          <p className='general-white-black'>
            Click the star anytime to share feature ideas or complaints. You can also report
            building environment concerns here.
          </p>
          <Button
            size='middle'
            className='system-gray-500 btn-shadow p-0 pl-0.25 pr-0.25 b-radius-4 ml-auto w-100 d-flex align-center justify-content-center btn-shadow b-radius-8 bg-white-gradient'
            onClick={handleInfoMessage}
          >
            <Text className='xs-medium general-black w-100 s-semibold general-white-black'>
              Got it!
            </Text>
          </Button>
        </Card>
      )}
      {openFeedbackModal && (
        <FeedbackModal
          show={openFeedbackModal}
          onClose={closeFeedBackModal}
          handleSubmit={handleSubmit}
        />
      )}
      {isSendFeedbackSuccessful && (
        <FeedbackSuccessModal show={openSuccessModal} onClose={() => setOpenSuccessModal(false)} />
      )}
    </Space>
  )
}
