import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { thingsBoardApi } from '@/redux-store/api/thingsboardApi'
import { feedbackApi } from '@/redux-store/reporting/feedback/feedback.service'
import feedbackReducer from '@/redux-store/reporting/feedback/feedback.slice'
import themeReducer from '@/redux-store/theme/theme.slice'
import assetsReducer from '@/redux-store/thingsboard/assets/assets.slice'
import authenticationReducer from '@/redux-store/thingsboard/authentication/authentication.slice'
import telemetryReducer from '@/redux-store/thingsboard/telemetry/telemetry.slice'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thingsBoardApi.middleware, feedbackApi.middleware),
  reducer: {
    assets: assetsReducer,
    authentication: authenticationReducer,
    feedback: feedbackReducer,
    telemetry: telemetryReducer,
    theme: themeReducer,
    [thingsBoardApi.reducerPath]: thingsBoardApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
