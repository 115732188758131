import { Button, Row, Space } from 'antd'
import noop from 'lodash/noop'
import React from 'react'

import { Icon } from '@/components'

import styles from './Avatar.module.scss'

type AvatarDropdownPropsType = {
  onClick?: () => void
}

const Avatar = (props: AvatarDropdownPropsType) => {
  const { onClick = noop } = props

  return (
    <Space className={styles.container}>
      <Button block onClick={onClick} className={`${styles.button} bg-body-background`}>
        <Row align='middle' justify='center'>
          <Icon iconName='user-circle' type='solid' size='sm' />
        </Row>
      </Button>
    </Space>
  )
}

export default Avatar
