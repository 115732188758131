import { Button, Checkbox, Form, Modal, Select, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { type PropsWithChildren, useState } from 'react'

import styles from '../Feedback.module.scss'

import FeedbackIcon from '@/components/Feedback/FeedbackIcon/FeedbackIcon'
import Icon from '@/components/Icon/Icon'
import Tabs from '@/components/Tabs/Tabs'
import { useAppSelector } from '@/redux-store/hooks'
import type { FeedbackDTO } from '@/redux-store/reporting/feedback/types'
import {
  ComplaintType,
  FeedbackType,
  FeedbackTypeText,
} from '@/redux-store/reporting/feedback/types'
import { getCurrentAssetsDataSelector } from '@/redux-store/thingsboard/assets/assets.selectors'

type FeedbackModalProps = PropsWithChildren & {
  show: boolean
  onClose: () => void
  handleSubmit: (data: FeedbackDTO) => void
}

const { Text } = Typography

const complaintType = [
  { label: 'Too hot', value: ComplaintType.HOT },
  { label: 'Too cold', value: ComplaintType.COLD },
  { label: 'Other', value: ComplaintType.OTHER },
]

export default function FeedbackModal(props: FeedbackModalProps) {
  const { show, onClose, handleSubmit } = props
  const [feedbackTypeText, setFeedbackTypeText] = useState(FeedbackTypeText.FEATURE_IDEA)
  const [feedbackType, setFeedbackType] = useState(FeedbackType.FEATURE_IDEA)
  const currentAsset = useAppSelector(getCurrentAssetsDataSelector)

  const tabItems = [{ label: FeedbackTypeText.FEATURE_IDEA }, { label: FeedbackTypeText.COMPLAINT }]

  function handleTabChange(label: string) {
    setFeedbackTypeText(label as FeedbackTypeText)
    if (label === FeedbackTypeText.FEATURE_IDEA) {
      setFeedbackType(FeedbackType.FEATURE_IDEA)
    }
    if (label === FeedbackTypeText.COMPLAINT) {
      setFeedbackType(FeedbackType.COMPLAINT)
    }
  }

  function handleFormSubmit(data: FeedbackDTO) {
    handleSubmit({
      ...data,
      assetId: currentAsset.id.id,
      assetName: currentAsset.name,
      type: feedbackType,
    })
  }

  return (
    <Modal
      open={show}
      centered
      footer={null}
      closable={false}
      width={450}
      className='custom-modal ant-modal-content'
    >
      <div className='absolute r-17'>
        <Button
          onClick={onClose}
          icon={<Icon iconName='x' type='regular' className='general-white-black' />}
          type='ghost'
        />
      </div>
      <div className='d-flex flex-row w-90 justify-content-start'>
        <div
          className={`${styles['icon-container']} bg-system-light-50-dark-100 pointer input-shadow b-radius-12`}
        >
          <FeedbackIcon />
        </div>
        <div className='w-70 ml-1'>
          <h3 className='text-left mb-0 general-white-black'>Feedback</h3>
          <p className='general-white-black'>
            Facing an issue with the building or have a feature idea? Share it with us! We read all
            feedback.
          </p>
        </div>
      </div>
      <div className={`w-100 feedback-data ${styles['feedback-data']}`}>
        <Tabs
          items={tabItems}
          onChange={handleTabChange}
          activeTab={feedbackTypeText}
          className='w-100'
        />
        <div>
          <Form
            name='feedback'
            layout='vertical'
            initialValues={{ canBeContacted: true, complaintType: ComplaintType.HOT }}
            onFinish={handleFormSubmit}
            requiredMark={false}
            autoComplete='off'
            size='large'
            disabled={false}
          >
            {feedbackType === FeedbackType.COMPLAINT && (
              <Form.Item name='complaintType' label='Complaint type' className='mt-1'>
                <Select options={complaintType} />
              </Form.Item>
            )}
            <Form.Item
              label={
                <Text className='s-medium general-white-black'>
                  {feedbackType === FeedbackType.FEATURE_IDEA ? 'Let us know:' : 'Message:'}
                </Text>
              }
              name='details'
              className='s-medium mt-1'
              rules={[
                {
                  max: 400,
                  required: true,
                },
              ]}
            >
              <TextArea rows={4} className='bg-system-light-50-dark-100 general-white-black' />
            </Form.Item>
            <div className='general-white-black d-flex flex-col bg-system-gray-100-dark-100 b-radius-12 p-0.25 input-shadow'>
              <div className='d-flex p-0.5'>
                <Icon
                  iconName='paperclip'
                  type='regular'
                  size='md'
                  className={`general-white-black ml-1 ${styles['paperclip']}`}
                />
                <div>
                  <span className='m-semibold'>Want to attach a file or screenshot?</span>
                  <p>
                    Drop it on Filemail as a link, then copy the link and add it to your message.
                  </p>
                </div>
              </div>
              <Button
                type='link'
                onClick={() => {
                  window.open('https://www.filemail.com/')
                }}
                className={`btn-shadow b-radius-8 w-50 d-flex align-center bg-white-gradient system-gray-500 ml-0.5 mb-0.5 ${styles['filemail']}`}
              >
                <Text className='s-semibold mr-0.5 general-white-black'>To Filemail</Text>
                <Icon
                  iconName='link-external'
                  type='regular'
                  size='xs'
                  className='general-white-black'
                />
              </Button>
            </div>
            <Form.Item
              className='s-medium w-100 d-inline-block mb-0 mt-1'
              // name='canBeContacted'
              valuePropName='checked'
            >
              <Checkbox className='general-white-black' defaultChecked>
                <span className='m-semibold'>I can be contacted about my feedback.</span>
                <p>We may want to contact you to collect some additional information.</p>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                size='large'
                className='w-100 m-semibold bg-blue general-white mt-1 text-center'
                type='default'
                htmlType='submit'
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
