import classnames from 'classnames'
import React from 'react'

import styles from './Icon.module.scss'

type IconPropsType = {
  iconName: string
  type: 'regular' | 'solid' | 'logo'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  border?: 'standard' | 'circle'
  color?: string
  className?: string
}

const cx = classnames.bind(styles)

const Icon = (props: IconPropsType) => {
  const { iconName, size = 'sm', type = 'regular', border, color, className = '' } = props

  const getPrefix = (): string => {
    if (type === 'solid') return 'bxs'
    if (type === 'logo') return 'bxl'

    return 'bx'
  }

  const getBorderStyle = (): string => {
    if (border === 'standard') return 'bx-border'
    if (border === 'circle') return 'bx-border-circle'

    return ''
  }

  const getIconName = (): string => {
    return `${getPrefix()}-${iconName}`
  }

  const iconClassName = cx({
    bx: true,
    [styles.icon]: true,
    [`bx-${type}`]: true,
    [`bx-${size}`]: size,
    [getIconName()]: true,
    [getBorderStyle()]: border,
    [className]: !!className,
  })

  return <i className={iconClassName} {...(color && { style: { color } })} />
}

export default React.memo(Icon)
