import { Row, Select, Typography } from 'antd'
import React from 'react'

import Icon from '@/components/Icon/Icon'
import type { TimePeriodKeysType } from '@/constants/utilities'
import { useAppDispatch, useAppSelector } from '@/redux-store/hooks'
import { getSelectedTimePeriodSelector } from '@/redux-store/thingsboard/telemetry/telemetry.selectors'
import { setTimePeriod } from '@/redux-store/thingsboard/telemetry/telemetry.slice'

import styles from './TimePeriodSelect.module.scss'

const { Text } = Typography
const { Option } = Select

const options = [
  { label: 'Hourly', value: 'hrs' },
  { label: 'Daily', value: 'day' },
  { label: 'Weekly', value: 'wek' },
  { label: 'Monthly', value: 'mth' },
]

export default function TimePeriodSelect() {
  const dispatch = useAppDispatch()
  const selectedTimePeriod = useAppSelector(getSelectedTimePeriodSelector)

  const onTimePeriodSelection = (value: string) => {
    dispatch(setTimePeriod(value as TimePeriodKeysType))
  }

  return (
    <div className={styles.container}>
      <Select
        value={selectedTimePeriod}
        popupClassName='w-fit-content xs-medium'
        suffixIcon={
          <Icon iconName='chevron-down' type='solid' size='xs' className='general-white-black' />
        }
        className={`xs-medium bg-custom-select general-white-black ${styles.text}`}
        onChange={onTimePeriodSelection}
        optionLabelProp='label'
        dropdownStyle={{ color: 'red' }}
      >
        {options.map(({ label, value }) => (
          <Option
            key={value}
            value={value}
            label={
              <Row justify='center' align='middle'>
                <Icon iconName='calendar' type='solid' size='xs' className='general-white-black' />
                &nbsp;
                <Text className='xs-medium general-white-black'>{label}</Text>
              </Row>
            }
          >
            {label}
          </Option>
        ))}
      </Select>
    </div>
  )
}
