import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function ElectricityIcon(props: DimensionProps) {
  const { width = '49', height = '48' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_1094_2406)'>
        <rect x='4' y='3' width='40' height='40' rx='20' fill='url(#paint0_linear_1094_2406)' />
        <path
          d='M22.8333 26.5H17L25.1667 10.1667V19.5H31L22.8333 35.8334V26.5Z'
          fill='url(#paint1_linear_1094_2406)'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_1094_2406'
          x='0'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_1094_2406'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1094_2406' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_1094_2406'
            result='effect2_dropShadow_1094_2406'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_1094_2406'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1094_2406'
          x1='24'
          y1='3'
          x2='24'
          y2='43'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D4D4D' />
          <stop offset='1' stopColor='#343434' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1094_2406'
          x1='24'
          y1='10.1667'
          x2='24'
          y2='35.8334'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FB4EFF' />
          <stop offset='1' stopColor='#69C0FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}
