export enum AssetType {
  BUILDING = 'building',
  BUILDING_LEVEL = 'building-level',
}

// TODO: refactor AssetsData properties when we have more knowledge about it
export type AssetsData = {
  additionalInfo: string | null
  assetProfileId: { entityType: string; id: string }
  createdTime: Date
  customerId: { entityType: string; id: string }
  externalId: null
  id: { entityType: string; id: string }
  label: string | null
  name: string
  tenantId: { entityType: string; id: string }
  type: string
}

export type AssetsDataResponseType = {
  data: Array<AssetsData>
  hasNext: boolean
  totalElements: number
  totalPages: number
}
