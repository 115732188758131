import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ThemeState {
  darkTheme: boolean
}

const initialState: ThemeState = {
  darkTheme: false,
}

export const themeSlice = createSlice({
  initialState,
  name: 'theme',
  reducers: {
    setDarkTheme: (state, action: PayloadAction<boolean>) => {
      state.darkTheme = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDarkTheme } = themeSlice.actions

export default themeSlice.reducer
