function isAcronym(word: string): boolean {
  // TODO: Update the list of acronyms as we encounter them in the system
  const acronyms: string[] = ['ac']

  return acronyms.includes(word.toLowerCase())
}

export function capitalizeWordsAndReplaceUnderscores(str: string): string {
  if (!str || typeof str !== 'string') {
    return ''
  }

  return str
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) =>
      isAcronym(word)
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ')
}

export function capitalizeReplaceAndRemoveFirst(str: string, replacement: string = '_'): string {
  if (!str || typeof str !== 'string') {
    return ''
  }

  return str
    .split(replacement)
    .slice(1)
    .map((word) =>
      isAcronym(word)
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ')
}
