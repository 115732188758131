import { Space, Tag, Typography } from 'antd'
import classNames from 'classnames'
import get from 'lodash/get'
import map from 'lodash/map'
import noop from 'lodash/noop'
import toPairs from 'lodash/toPairs'
import React, { useState } from 'react'

import FddStateModal from '@/components/SystemConsumptionList/SystemConsumptionModal/SystemConsumptionDetailsTable/FddStateModal/FddStateModal'
import { useScreenSize } from '@/hooks'
import type {
  EquipmentConsumptionResponseValueType,
  EquipmentConsumptionType,
  SystemFddStateResponseRuleValueType,
  SystemFddStateResponseValueType,
} from '@/redux-store/thingsboard/telemetry/types'

const { Text } = Typography

type SystemConsumptionDetailsTableProps = {
  equipment: EquipmentConsumptionResponseValueType
  fddStateData: SystemFddStateResponseValueType
  isFddStateLoading: boolean
}

function SystemConsumptionDetailsTable(props: SystemConsumptionDetailsTableProps) {
  const { equipment, fddStateData, isFddStateLoading } = props
  const [selectedEquipment, setSelectedEquipment] = useState('')
  const [isFddModalOpen, setIsFddModalOpen] = useState(false)

  const handleOnRowClick = (equipmentName: string) => () => {
    setSelectedEquipment(equipmentName)
    setIsFddModalOpen(true)
  }

  const handleOnFddModalClose = () => {
    setIsFddModalOpen(false)
  }

  return (
    <Space direction='vertical' className='mt-1.5 gap-0'>
      {map(toPairs(equipment), ([equipmentName, equipmentUnit]) => (
        <EquipmentTableRow
          key={equipmentName}
          title={equipmentName}
          onClick={handleOnRowClick(equipmentName)}
          equipmentUnit={equipmentUnit as EquipmentConsumptionType}
        />
      ))}

      {isFddModalOpen && (
        <FddStateModal
          isLoading={isFddStateLoading}
          isOpen={isFddModalOpen}
          onClose={handleOnFddModalClose}
          data={get(fddStateData, selectedEquipment) as SystemFddStateResponseRuleValueType}
          subtitle={selectedEquipment}
        />
      )}
    </Space>
  )
}

type EquipmentTableRowProps = {
  title: string
  equipmentUnit: EquipmentConsumptionType
  onClick?: (equipmentName: string) => void
}

const EquipmentTableRow = React.memo((props: EquipmentTableRowProps) => {
  const { title, equipmentUnit, onClick = noop } = props
  const { isScreenMobile } = useScreenSize()

  const getEnabledTagClassNames = {
    tag: classNames('pl-1 pr-1', {
      'bg-green-gradient': equipmentUnit.enable > 0,
      'bg-system-gray-100': equipmentUnit.enable === 0,
    }),
    text: classNames('subtitle', {
      'general-white-black': equipmentUnit.enable > 0,
      'system-gray-500': equipmentUnit.enable === 0,
    }),
  }

  const inefficiencies = equipmentUnit.inefficiencies ?? 0
  const faults = equipmentUnit.faults ?? 0

  return (
    <Space.Compact
      block
      className='bt-light pointer'
      direction={isScreenMobile ? 'vertical' : 'horizontal'}
      onClick={onClick}
    >
      <Space className={`w-50 p-1 ${!isScreenMobile && 'br-light'}`}>
        <Text className='m-medium general-white-black'>{title}</Text>
      </Space>
      <Space.Compact block className='p-1 justify-content-around'>
        <>
          <Tag className={getEnabledTagClassNames.tag}>
            <Text className={getEnabledTagClassNames.text}>{equipmentUnit.enable}</Text>
          </Tag>
          <Tag
            className={`b-radius-4 pt-0.25 pb-0.25 ${
              inefficiencies > 0 ? 'bg-orange' : 'bg-transparent'
            }`}
          >
            <Text
              className={`subtitle ${inefficiencies > 0 ? 'general-white' : 'system-gray-500-300'}`}
            >
              {inefficiencies} INEFFICIENCIES
            </Text>
          </Tag>
          <Tag
            className={`b-radius-4 ${
              faults > 0 ? 'bg-general-red' : 'bg-transparent'
            } pt-0.25 pb-0.25`}
          >
            <Text className={`subtitle ${faults > 0 ? 'general-white' : 'system-gray-500-300'}`}>
              {faults} FAULTS
            </Text>
          </Tag>
        </>
      </Space.Compact>
    </Space.Compact>
  )
})

export default React.memo(SystemConsumptionDetailsTable)
