import { Button, Modal, Space, Typography } from 'antd'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import type { PropsWithChildren } from 'react'

import Icon from '@/components/Icon/Icon'

const { Title, Text } = Typography

type ActionModalProps = PropsWithChildren & {
  title: string
  description: string
  btnText: string
  btnDisabled?: boolean
  show: boolean
  iconName: string
  onClick?: () => void
  onClose: () => void
}

export default function ActionModal(props: ActionModalProps) {
  const {
    description,
    title,
    btnText,
    show,
    btnDisabled = false,
    children,
    onClick = noop,
    onClose,
    iconName,
  } = props

  const debouncedOnClick = debounce(onClick, 150)
  return (
    <Modal
      open={show}
      centered
      footer={null}
      closable={false}
      width={442}
      className='custom-modal ant-modal-content'
    >
      <div className='absolute r-17'>
        <Button
          onClick={onClose}
          icon={<Icon iconName='x' type='regular' className='general-white-black' />}
          type='ghost'
        />
      </div>
      <Space className='w-100 pt-2' direction='vertical' size='middle'>
        <div>
          <Icon
            iconName={iconName}
            type='solid'
            border='circle'
            size='sm'
            className='p-2 general-white-black bg-general-black-white'
          />
        </div>
        <Space direction='vertical' size='middle'>
          <Title level={3} className='m-0 general-white-black'>
            {title}
          </Title>
          <Text className='s-regular system-gray-700 text-center system-gray-700-300'>
            {description}
          </Text>
        </Space>
        <Space direction='vertical' className='w-100 mt-0.5'>
          {children}
          <Button
            size='large'
            disabled={btnDisabled}
            onClick={debouncedOnClick}
            className='input-shadow bg-white-gradient w-100'
          >
            <Text className='m-semibold general-white-black'>{btnText}</Text>
          </Button>
        </Space>
      </Space>
    </Modal>
  )
}
