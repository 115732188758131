import type { BaseQueryApi, BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/dist/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { Mutex } from 'async-mutex'
import jwt_decode from 'jwt-decode'

import { REFRESH_TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY } from '@/constants/storageKeys'
import { THINGS_BOARD_BASE_URL } from '@/constants/urls'
import type { AppDispatch } from '@/redux-store/store'
import { setIsLoggedIn } from '@/redux-store/thingsboard/authentication/authentication.slice'
import type { DecodedTokenType } from '@/redux-store/thingsboard/authentication/types'
import { UserRoles } from '@/redux-store/thingsboard/authentication/types'
import { fetchFn } from '@/utils/ky'
import { getStorageItem, removeStorageItem, setStorageItem } from '@/utils/storage'

const refreshTokenQuery = fetchBaseQuery({
  baseUrl: `${THINGS_BOARD_BASE_URL}`,
  fetchFn,
})

const mutex = new Mutex()

export const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  baseQueryFunction: BaseQueryFn
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  let result: any = await baseQueryFunction(args, api, extraOptions)

  if (result.error && (result.error.status === 401 || result.error.status === 'FETCH_ERROR')) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshToken = getStorageItem(REFRESH_TOKEN_STORAGE_KEY)

        if (decodeTokenAndGetTimeDifference(refreshToken) <= 0) {
          removeUserTokensAndDispatchLogout(api.dispatch)
        } else {
          // get RefreshToken
          const refreshResult: any = await refreshTokenQuery(
            {
              body: {
                refreshToken: refreshToken,
              },
              method: 'POST',
              url: 'auth/token',
            },
            api,
            extraOptions
          )

          if (refreshResult.data) {
            // store new token and new refresh token
            setStorageItem({ key: TOKEN_STORAGE_KEY, value: refreshResult.data.token })
            setStorageItem({
              key: REFRESH_TOKEN_STORAGE_KEY,
              value: refreshResult.data.refreshToken,
            })

            // retry the initial query
            result = await baseQueryFunction(args, api, extraOptions)
          } else {
            removeUserTokensAndDispatchLogout(api.dispatch)
          }
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQueryFunction(args, api, extraOptions)
    }
  }
  return result
}

export const decodeTokenAndGetTimeDifference = (token: string) => {
  if (token) {
    const { exp: expTimeInSeconds } = jwt_decode(token) as DecodedTokenType
    const currentTimeInSeconds = Math.round(Date.now() / 1000)
    const timeDifferenceInSeconds = expTimeInSeconds - currentTimeInSeconds
    return timeDifferenceInSeconds
  } else {
    return -1
  }
}

export const removeUserTokensAndDispatchLogout = (dispatch: AppDispatch) => {
  removeStorageItem(REFRESH_TOKEN_STORAGE_KEY)
  removeStorageItem(TOKEN_STORAGE_KEY)
  dispatch(setIsLoggedIn(false))
}

export const getCustomerId = (token: string) => {
  if (token) {
    const { customerId: cid } = jwt_decode(token) as DecodedTokenType
    return cid
  } else {
    return null
  }
}

export const getUserScope = (token: string) => {
  if (token) {
    const { scopes: userScopes } = jwt_decode(token) as DecodedTokenType
    return userScopes
  } else {
    return []
  }
}

export const isTenantAdmin = (roles: string[] = []) => {
  return roles.includes(UserRoles.TENANT_ADMIN)
}

export const isCustomerUser = (roles: string[] = []) => {
  return roles.includes(UserRoles.CUSTOMER_USER)
}
