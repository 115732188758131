import { Col, Row, Space, Typography } from 'antd'
import React from 'react'

import { CarbonIconLight, ElectricityIconLight, FiscalIconLight } from '@/components/LiveDataIcons'
import StatsRow from '@/components/SystemConsumptionList/SystemConsumptionCard/StatsContainer/StatsRow'
import { GBP, KG_CO2, KWH } from '@/constants/units'
import { useScreenSize } from '@/hooks'
import type { SystemConsumptionByUtilityType } from '@/redux-store/thingsboard/telemetry/types'

const { Text } = Typography

export default function StatsContainer(props: SystemConsumptionByUtilityType) {
  const { energy, carbon, fiscal } = props

  const { isScreenDesktop } = useScreenSize()
  return (
    <Space.Compact
      direction='vertical'
      block
      className={`pr-0.5 pl-0.5 bg-system-white-gray-800 pt-0.5 pb-0.5 justify-content-center ${
        isScreenDesktop ? 'b-right-radius-8' : 'b-left-bottom-radius-8 b-right-bottom-radius-8'
      }`}
    >
      <HeaderRow />
      <StatsRow
        icon={<ElectricityIconLight width={25} height={25} />}
        actual={energy.actual}
        modelled={energy.modelled}
        saved={energy.saved}
        unit={KWH}
      />
      <StatsRow
        icon={<CarbonIconLight width={25} height={25} />}
        actual={carbon.actual}
        modelled={carbon.modelled}
        saved={carbon.saved}
        unit={KG_CO2}
      />
      <StatsRow
        icon={<FiscalIconLight width={25} height={25} />}
        actual={fiscal.actual}
        modelled={fiscal.modelled}
        saved={fiscal.saved}
        unit={GBP}
      />
    </Space.Compact>
  )
}

function HeaderRow() {
  return (
    <Row align='middle' justify='center' className='pb-0.5'>
      <Col span={3}></Col>
      {['Actual', 'Modelled', 'Savings'].map((title, index) => (
        <Col key={index} span={7}>
          <Text className='subtitle system-gray-500-300'>{title}</Text>
        </Col>
      ))}
    </Row>
  )
}
