import { Button, ConfigProvider, Typography } from 'antd'
import React from 'react'

const { Text } = Typography

const theme = {
  components: {
    Button: {
      controlHeight: 24,
    },
  },
}

function FullScreenButton() {
  return (
    <div className='d-flex align-center'>
      <ConfigProvider theme={theme}>
        <Button
          size='small'
          className='system-gray-500 btn-shadow p-0 pl-0.25 pr-0.25 b-radius-4 ml-auto'
        >
          <Text className='xs-medium general-black'>F11</Text>
        </Button>
      </ConfigProvider>
      <Text className='xs-regular system-gray-500 ml-0.75 system-gray-500-200'>
        Full-Screen View On/Off
      </Text>
    </div>
  )
}

export default React.memo(FullScreenButton)
