import { Button, Divider, Modal, Row, Skeleton, Space, Typography } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import toPairs from 'lodash/toPairs'
import React from 'react'

import Icon from '@/components/Icon/Icon'
import FddStateTable from '@/components/SystemConsumptionList/SystemConsumptionModal/SystemConsumptionDetailsTable/FddStateModal/FddStateTable'
import type {
  SystemFddStateResponseRuleValueType,
  SystemFddStateRuleType,
} from '@/redux-store/thingsboard/telemetry/types'
import { capitalizeWordsAndReplaceUnderscores } from '@/utils/stringUtils'

const { Text, Title } = Typography

type FddStateModalPropsType = {
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  data: SystemFddStateResponseRuleValueType
  subtitle: string
}

function FddStateModal(props: FddStateModalPropsType) {
  const { isLoading, isOpen, subtitle, data, onClose } = props

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      centered
      footer={null}
      closable={false}
      width={674}
      className='custom-modal ant-modal-content'
    >
      <div className='absolute r-17'>
        <Button
          onClick={onClose}
          icon={<Icon iconName='x' type='regular' className='general-white-black' />}
          type='ghost'
        />
      </div>
      <Skeleton active={isLoading} loading={isLoading} round>
        <Space.Compact block direction='vertical'>
          <Space direction='vertical' align='start' size='small'>
            <Title level={3} className='m-medium general-white-black m-0'>
              Faults and Inefficiencies
            </Title>
            <Text className='m-semibold system-gray-500-300 mb-2'>
              {capitalizeWordsAndReplaceUnderscores(subtitle)}
            </Text>
          </Space>
          <Space.Compact block direction='vertical'>
            {!isEmpty(data) ? (
              map(toPairs(data), ([equipmentName, fdd]: [string, SystemFddStateRuleType[]]) => (
                <Space.Compact block direction='vertical' key={equipmentName}>
                  {size(data) && (
                    <Row>
                      <Icon
                        iconName='chip'
                        type='solid'
                        size='xs'
                        className='l-semibold system-gray-500-300 mr-0.25'
                      />
                      <Text className='l-semibold system-gray-500-300 mb-1'>{equipmentName}</Text>
                    </Row>
                  )}
                  <FddStateTable faults={fdd} />
                  {size(data) > 1 && (
                    <Divider type='horizontal' className='mt-0.75 bg-system-gray-50-800' />
                  )}
                </Space.Compact>
              ))
            ) : (
              <Text className='l-semibold system-gray-500-300 mt-1 mb-1 text-center'>
                No results
              </Text>
            )}
          </Space.Compact>
        </Space.Compact>
      </Skeleton>
    </Modal>
  )
}

export default FddStateModal
