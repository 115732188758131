import { getFullNameFromAbbreviation } from '@/utils/abbreviations'

function getActualOrBenchmarkLabel(label: string): string {
  return label.toLowerCase().endsWith('benchmark') ? 'Benchmark' : 'Actual'
}

type Item = {
  name: string
  value: string
  data: {
    type: string
  }
}

export function groupItemsByUtilType<T extends Item>(items: T[]): Record<string, T[]> {
  return items.reduce((grouped: Record<string, T[]>, item: T) => {
    const utilType = item.data.type.split('-')[0]
    grouped[utilType] = [...(grouped[utilType] || []), item]
    return grouped
  }, {})
}

export const tooltipContentFormater = (title: string, items: Item[]) => {
  const groupedItemsByUtilType: Record<string, Item[]> = groupItemsByUtilType<Item>(items)

  const groupKeys = Object.keys(groupedItemsByUtilType)
  return `
    <div class='pt-0.5 pb-0.5'>
      <div class='xs-semibold system-gray-100'>${getFullNameFromAbbreviation(title)}</div>
      <hr />
      ${groupKeys
        .map((type, index) => {
          const items = groupedItemsByUtilType[type]
          const itemHtml = items
            .map(
              (item) => `
                <div class='d-flex justify-content-between'>
                  <div>${getActualOrBenchmarkLabel(item.name)}</div>
                  <div class='xs-semibold system-gray-100 pl-1'>${item.value}</div>
                </div>
              `
            )
            .join('')
          const isLastGroup = index === groupKeys.length - 1
          return `
            <div>
              <div class='xs-medium system-gray-100 pb-0.5'>${type}</div>
              ${itemHtml}
            </div>
            ${isLastGroup ? '' : '<hr />'}
          `
        })
        .join('')}
    </div>
  `
}
