export enum SystemsType {
  ELECTRICAL_SERVICE = 'electrical_service',
  GAS_SERVICE = 'gas_service',
  HEATING_SYSTEM = 'heating_system',
  COOLING_SYSTEM = 'cooling_system',
  PACKAGED_AC = 'packaged_ac',
  VENTILATION_SYSTEM = 'ventilation_system',
  LIGHTING_SYSTEM = 'lighting_system',
  SMALL_POWER = 'small_power',
  DOMESTIC_SERVICES = 'domestic_services',
  CRITICAL_COOL = 'critical_cool',
  CATERING = 'catering',
  VERTICAL_TRANSPORTATION = 'vertical_transportation',
}

export type SystemsKeyType =
  | 'catering'
  | 'cooling_system'
  | 'domestic_services'
  | 'heating_system'
  | 'lighting_system'
  | 'packaged_ac'
  | 'small_power'
  | 'ventilation_system'
  | 'vertical_transportation'

export type SystemConsumptionUtilityKeyType = 'carbon' | 'fiscal' | 'energy'
export type SystemConsumptionUtilityUsageKeyType = 'actual' | 'modelled' | 'saved'
export type SystemConsumptionFddDataKeyType = 'inefficiencies' | 'faults'

export type EquipmentKey =
  | 'Floor 00'
  | 'Floor 001'
  | 'Floor 002'
  | 'Floor 003'
  | 'Floor 004'
  | 'chl0001'
  | 'pmp01ph'
  | 'pmp02ph'
  | 'pmp03sh'
  | 'pmp03sh'
  | 'pmp04sh'
  | 'blr0001'
  | 'ahu0001'
  | 'fan0001'

export type AlertsKeyType = 'alertPanelA' | 'alertPanelB' | 'alertPanelC'
