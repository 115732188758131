import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function CarbonIcon(props: DimensionProps) {
  const { width = '49', height = '48' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 49 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_23_317)'>
        <rect x='4.37598' y='3' width='40' height='40' rx='20' fill='white' />
        <path
          d='M36.0423 12.9788L35.9023 11.5088L34.5023 11.9755C32.1029 12.8091 29.5407 13.0649 27.0239 12.7221C24.3793 12.3117 21.6723 12.6764 19.2306 13.7721C17.9362 14.3233 16.8001 15.1895 15.926 16.2919C15.0518 17.3943 14.4673 18.6978 14.2256 20.0838C13.9122 21.8743 14.069 23.7154 14.6806 25.4271C15.0744 24.5382 15.5346 23.6802 16.0573 22.8605C17.2428 21.1161 18.8856 19.7317 20.8056 18.8588C22.6553 18.0143 24.6771 17.6147 26.7089 17.6921C24.7746 17.9879 22.9231 18.6832 21.2723 19.7338C20.476 20.2341 19.7468 20.834 19.1023 21.5188C18.4859 22.2033 17.9424 22.9501 17.4806 23.7471C16.6132 25.4131 15.996 27.1979 15.6489 29.0438C15.2748 30.8957 15.0717 32.778 15.0423 34.6671H17.3756C17.4853 32.9729 17.7153 31.2887 18.0639 29.6271C19.6826 30.5012 21.4978 30.947 23.3373 30.9221C25.0528 30.9209 26.7507 30.5758 28.3306 29.9071C37.2089 26.1155 36.0423 13.5038 36.0423 12.9788Z'
          fill='url(#paint0_linear_23_317)'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_23_317'
          x='0.375977'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_23_317'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_23_317' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_23_317'
            result='effect2_dropShadow_23_317'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_23_317'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_23_317'
          x1='25.0806'
          y1='11.5088'
          x2='25.0806'
          y2='34.6671'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#62DE00' />
          <stop offset='1' stopColor='#006382' />
        </linearGradient>
      </defs>
    </svg>
  )
}
