import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function WaterIcon(props: DimensionProps) {
  const { width = '49', height = '48' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 49 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_202_2046)'>
        <rect x='4.37598' y='3' width='40' height='40' rx='20' fill='white' />
        <g clipPath='url(#clip0_202_2046)'>
          <mask
            id='mask0_202_2046'
            style={{ maskType: 'luminance' }}
            maskUnits='userSpaceOnUse'
            x='14'
            y='10'
            width='21'
            height='26'
          >
            <path
              d='M24.376 33.4163C28.6906 33.4163 32.1885 29.9184 32.1885 25.6038C32.1885 18.3122 24.376 12.583 24.376 12.583C24.376 12.583 16.5635 18.3122 16.5635 25.6038C16.5635 29.9184 20.0614 33.4163 24.376 33.4163Z'
              stroke='white'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M16.5635 25.6036C16.5635 29.9182 20.0614 33.4161 24.376 33.4161C28.6906 33.4161 32.1885 29.9182 32.1885 25.6036C32.1885 25.6036 27.501 27.1661 24.376 25.6036C21.251 24.0411 16.5635 25.6036 16.5635 25.6036Z'
              fill='white'
              stroke='white'
              strokeWidth='4'
              strokeLinejoin='round'
            />
          </mask>
          <g mask='url(#mask0_202_2046)'>
            <path d='M11.876 10.5H36.876V35.5H11.876V10.5Z' fill='url(#paint0_linear_202_2046)' />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_dd_202_2046'
          x='0.375977'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_202_2046'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_202_2046' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_202_2046'
            result='effect2_dropShadow_202_2046'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_202_2046'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_202_2046'
          x1='24.376'
          y1='10.5'
          x2='24.376'
          y2='35.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1EA0FF' />
          <stop offset='1' stopColor='#1ED7CC' />
        </linearGradient>
        <clipPath id='clip0_202_2046'>
          <rect width='25' height='25' fill='white' transform='translate(11.876 10.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}
