import { Button, Checkbox, Form, Input, Row, Typography } from 'antd'

import { REMEMBER_ME } from '@/constants/storageKeys'
import InputErrorMessage from '@/features/authentication/components/InputErrorMessage/InputErrorMessage'
import type { LoginQueryArgs } from '@/redux-store/thingsboard/authentication/types'
import { getStorageItem } from '@/utils/storage'

const { Text } = Typography

type LogInFormPropsType = {
  handleSubmit: (credentials: LoginQueryArgs) => void
  handleForgotPasswordClick: () => void
  handleRememberMeClick: () => void
  isLoading: boolean
}

export default function LogInForm(props: LogInFormPropsType) {
  const { handleSubmit, isLoading, handleForgotPasswordClick, handleRememberMeClick } = props
  return (
    <Form
      name='log-in'
      layout='vertical'
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      requiredMark={false}
      autoComplete='off'
      size='large'
      disabled={isLoading}
    >
      <Form.Item
        label={<Text className='s-medium general-white-black'>Email Address:</Text>}
        name='username'
        className='s-medium'
        rules={[
          { message: <InputErrorMessage message='Invalid email' />, required: true, type: 'email' },
        ]}
      >
        <Input
          className='s-regular bg-general-white-gray-900 general-white-black'
          disabled={isLoading}
        />
      </Form.Item>

      <Form.Item
        label={<Text className='s-medium general-white-black'>Password:</Text>}
        name='password'
        className='s-medium pt-1'
        rules={[
          {
            message: <InputErrorMessage message='Invalid password' />,
            required: true,
          },
        ]}
      >
        <Input
          type='password'
          className='s-regular bg-general-white-gray-900 general-white-black'
          disabled={isLoading}
        />
      </Form.Item>

      <Row justify='space-between' className='mb-2'>
        <Form.Item
          className='s-medium w-50 d-inline-block mb-0'
          name='remember'
          valuePropName={getStorageItem(REMEMBER_ME) ? 'checked' : ''}
        >
          <Checkbox onClick={handleRememberMeClick} className='general-white-black'>
            Remember me
          </Checkbox>
        </Form.Item>

        <Button
          type='link'
          onClick={handleForgotPasswordClick}
          className='s-links p-0 system-sky-blue-700-200'
        >
          Forgot your password?
        </Button>
      </Row>

      <Form.Item>
        <Button
          size='large'
          className='w-100 m-semibold bg-white-gradient general-white-black'
          type='default'
          htmlType='submit'
        >
          Sign In
        </Button>
      </Form.Item>
    </Form>
  )
}
