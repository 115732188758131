import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface FeedbackState {
  feedback: any
}

const initialState: FeedbackState = {
  feedback: null,
}

export const feedbackSlice = createSlice({
  initialState,
  name: 'feedback',
  reducers: {
    setFeedbackData: (state, action: PayloadAction<any>) => {
      state.feedback = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setFeedbackData } = feedbackSlice.actions

export default feedbackSlice.reducer
