export enum UtilityType {
  ELECTRICITY = 'Electricity',
  WATER = 'Water',
  GAS = 'Gas',
  CARBON = 'Carbon',
  FISCAL = 'Fiscal',
  COMFORT_SCORE = 'ComfortScore',
}

export enum UtilityTypeText {
  ELECTRICITY = 'Electricity',
  WATER = 'Water',
  GAS = 'Gas',
  CARBON = 'Carbon',
  FISCAL = 'Fiscal',
  COMFORT_SCORE = 'Comfort',
}

export enum LowercasedUtilityType {
  ELECTRICITY = 'elec',
  WATER = 'water',
  GAS = 'gas',
  CARBON = 'carbon',
  FISCAL = 'fiscal',
  ENV = 'env',
}

export type UtilitiesKeyType =
  | 'primary_electricity'
  | 'primary_gas'
  | 'primary_water'
  | 'space_heating'
  | 'space_cooling'
  | 'ventilation'
  | 'lighting'
  | 'power'
  | 'water_heating'
  | 'critical_cool'
  | 'catering'
  | 'other'
  | 'fiscal'
  | 'carbon'

export type TimePeriodKeysType = 'day' | 'hrs' | 'mth' | 'wek'

export enum UtilityUnits {
  GBP = 'GBP',
  KWH = 'kWh',
  KW = 'kW',
  LITRES = 'litres',
  CO2_KG = 'CO2kg',
}

export const UtilityUnitsByType = {
  [UtilityType.CARBON]: UtilityUnits.CO2_KG,
  [UtilityType.ELECTRICITY]: UtilityUnits.KWH,
  [UtilityType.FISCAL]: UtilityUnits.GBP,
  [UtilityType.GAS]: UtilityUnits.KWH,
  [UtilityType.WATER]: UtilityUnits.LITRES,
  [UtilityType.COMFORT_SCORE]: '',
}

export const UtilityUnitsText = {
  [UtilityType.CARBON]: UtilityTypeText.CARBON,
  [UtilityType.ELECTRICITY]: UtilityTypeText.ELECTRICITY,
  [UtilityType.FISCAL]: UtilityTypeText.FISCAL,
  [UtilityType.GAS]: UtilityTypeText.GAS,
  [UtilityType.WATER]: UtilityTypeText.WATER,
  [UtilityType.COMFORT_SCORE]: UtilityTypeText.COMFORT_SCORE,
}

export const POLLING_INTERVAL_IN_MS = 15 * 60 * 1000 // 15 min

export enum ComfortScoresEnum {
  Bad,
  Poor,
  Moderate,
  Good,
  Excellent,
}

export const ComfortScoresText = ['Bad', 'Poor', 'Moderate', 'Good', 'Excellent']
