export enum FeedbackTypeText {
  FEATURE_IDEA = 'Feature idea',
  COMPLAINT = 'Complaint',
}

export enum FeedbackType {
  FEATURE_IDEA = 'idea',
  COMPLAINT = 'complaint',
}

export enum ComplaintType {
  HOT = 'hot',
  COLD = 'cold',
  OTHER = 'other',
}

export interface FeedbackDTO {
  details: string
  type: string
  complaintType: ComplaintType | null
  assetId: string
  assetName: string
}

export interface FeedBackResponse extends FeedbackDTO {
  createdAt: string
  id: string
  user: string
}
