import { Column } from '@ant-design/charts'
import { Space, Typography } from 'antd'
import React from 'react'

import TimePeriodSelect from '@/components/TimePeriodSelect/TimePeriodSelect'
import type { SystemsKeyType } from '@/constants/systems'
import type { UsageChartDataFormat } from '@/redux-store/thingsboard/telemetry/types'
import { getChartConfig } from '@/utils'
import { capitalizeWordsAndReplaceUnderscores } from '@/utils/stringUtils'
import colors from 'styles/colors.module.scss'

const fieldName = 'type'

const columnConfig = getChartConfig({
  fieldName,
  getColor: () => colors.skyBlue500,
})

const { Title } = Typography

type PerformanceChartPropsType = {
  title: SystemsKeyType
  data: UsageChartDataFormat[]
}

export default function PerformanceChart(props: PerformanceChartPropsType) {
  const { title, data } = props

  return (
    <Space
      direction='vertical'
      className='w-100 b-radius-12 input-shadow pt-1 pb-1 pl-1 bg-system-light-50-dark-100'
      prefixCls='w-100'
    >
      <Space className='justify-content-between w-100 pr-2'>
        <Space size='small' className='align-center'>
          <Title level={5} className='m-0 general-white-black'>
            {capitalizeWordsAndReplaceUnderscores(`Overall ${title} Performance`)}
          </Title>
        </Space>
        <TimePeriodSelect />
      </Space>
      <Column {...columnConfig} data={data} />
    </Space>
  )
}
