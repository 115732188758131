import { Button, Modal } from 'antd'
import { type PropsWithChildren } from 'react'

import styles from '../Feedback.module.scss'

import FeedbackIcon from '@/components/Feedback/FeedbackIcon/FeedbackIcon'
import Icon from '@/components/Icon/Icon'

type FeedbackModalProps = PropsWithChildren & {
  show: boolean
  onClose: () => void
}

export default function FeedbackSuccessModal(props: FeedbackModalProps) {
  const { show, onClose } = props
  return (
    <Modal
      open={show}
      centered
      footer={null}
      closable={false}
      width={320}
      className='custom-modal ant-modal-content'
    >
      <div className='absolute r-17'>
        <Button
          onClick={onClose}
          icon={<Icon iconName='x' type='regular' className='general-white-black' />}
          type='ghost'
        />
      </div>
      <div className='d-flex flex-col justify-content-center align-center text-center'>
        <div
          className={`${styles['icon-container']} bg-system-light-50-dark-100 pointer input-shadow b-radius-12 m-auto`}
        >
          <FeedbackIcon />
        </div>
        <div className='mt-1'>
          <span className='m-semibold general-white-black'>Thank you for your feedback.</span>
          <p className='general-white-black'>
            It is invaluable during this stage of Attain. We can not wait to get even better for
            you.
          </p>
        </div>
      </div>
      <Button
        size='large'
        className='w-100 m-semibold bg-blue general-white mt-1 text-center d-flex justify-content-center align-center'
        type='default'
        onClick={() => onClose()}
      >
        Got it!
      </Button>
    </Modal>
  )
}
