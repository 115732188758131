import { Space, Typography } from 'antd'
import noop from 'lodash/noop'

import { Icon } from '@/components'

type LogoutButtonPropsType = {
  label?: string
  onClick?: () => void
}
const { Text } = Typography

const LogoutButton = (props: LogoutButtonPropsType) => {
  const { label = 'Log Out', onClick = noop } = props
  return (
    <Space align='center' className='container' onClick={onClick}>
      <Icon iconName='log-in' type='regular' size='sm' className='general-white-black' />
      <Text strong className='general-white-black'>
        {label}
      </Text>
    </Space>
  )
}

export default LogoutButton
