export const setStorageItem = ({ key, value }: { key: string; value: string | boolean }) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getStorageItem(key: string) {
  const storageItem = localStorage.getItem(key)
  let parsedStorageItem = null
  if (!storageItem) return parsedStorageItem
  try {
    parsedStorageItem = JSON.parse(storageItem)
  } catch (error) {
    // eslint-disable-next-line
    console.log(error)
  }

  return parsedStorageItem
}

export const removeStorageItem = (key: string) => {
  localStorage.removeItem(key)
}
