import { Divider, Space } from 'antd'
import { useParams } from 'react-router-dom'

import { UtilityLiveVsTargetDataPill } from '@/components'
import UtilityLiveDataPill from '@/components/UtilityLiveDataPill/UtilityLiveDataPill'
import { SystemsType } from '@/constants/systems'
import { DEFAULT_TARGET_TIME_DATA } from '@/constants/units'
import { POLLING_INTERVAL_IN_MS, UtilityType } from '@/constants/utilities'
import { useIsSystemActive, useScreenSize } from '@/hooks'
import { useAppSelector } from '@/redux-store/hooks'
import { getSelectedTimePeriodSelector } from '@/redux-store/thingsboard/telemetry/telemetry.selectors'
import { useGetSummaryTelemetryQuery } from '@/redux-store/thingsboard/telemetry/telemetry.service'
import type { TotalUsageByUtilityType } from '@/redux-store/thingsboard/telemetry/types'

import styles from './LiveBar.module.scss'

export default function LiveBar() {
  const { id = '' } = useParams()
  const { isScreenDesktop } = useScreenSize()
  const selectedTimePeriod = useAppSelector(getSelectedTimePeriodSelector)
  const { currentData: telemetryData, isLoading } = useGetSummaryTelemetryQuery(id, {
    pollingInterval: POLLING_INTERVAL_IN_MS,
    skip: !id,
  })
  const { runningBenchmarks, totalUsage } = telemetryData || {}

  const totalUsageForSpecificTime =
    totalUsage?.[selectedTimePeriod] || ({} as TotalUsageByUtilityType)

  const {
    carbon: targetCarbon,
    elec: targetElectricity,
    fiscal: targetFiscal,
    gas: targetGas,
    water: targetWater,
  } = runningBenchmarks?.[selectedTimePeriod] || DEFAULT_TARGET_TIME_DATA

  const isGasSystemActive = useIsSystemActive(SystemsType.GAS_SERVICE)

  return (
    <Space size={12} className={`${styles.container} nav-shadow`} wrap>
      <UtilityLiveVsTargetDataPill
        targetData={targetElectricity}
        utilityType={UtilityType.ELECTRICITY}
        liveData={totalUsageForSpecificTime?.elec}
        isLoading={isLoading}
      />
      <UtilityLiveVsTargetDataPill
        targetData={targetWater}
        utilityType={UtilityType.WATER}
        liveData={totalUsageForSpecificTime?.water}
        isLoading={isLoading}
      />
      {isGasSystemActive && (
        <UtilityLiveVsTargetDataPill
          targetData={targetGas}
          utilityType={UtilityType.GAS}
          liveData={totalUsageForSpecificTime?.gas}
          isLoading={isLoading}
        />
      )}
      {isScreenDesktop && <Divider type='vertical' style={{ height: '3rem' }} />}
      <UtilityLiveVsTargetDataPill
        targetData={targetCarbon}
        utilityType={UtilityType.CARBON}
        liveData={totalUsageForSpecificTime?.carbon}
        isLoading={isLoading}
      />
      <UtilityLiveVsTargetDataPill
        targetData={targetFiscal}
        utilityType={UtilityType.FISCAL}
        liveData={totalUsageForSpecificTime?.fiscal}
        isLoading={isLoading}
      />
      <UtilityLiveDataPill
        utilityType={UtilityType.COMFORT_SCORE}
        liveData={totalUsageForSpecificTime?.env}
        isLoading={isLoading}
      />
    </Space>
  )
}
