import { Col, Layout, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'

import { ActionModal, AppFooter } from '@/components'
import { LOGIN_MUTATION_FIXED_CACHE_KEY } from '@/constants/rtk-related-keys'
import { REMEMBER_ME } from '@/constants/storageKeys'
import LogInImage from '@/features/authentication/components/LogInImage/LogInImage'
import ResetPasswordModal from '@/features/authentication/components/ResetPasswordModal/ResetPasswordModal'
import UnauthorizedHeader from '@/features/authentication/components/UnauthorizedHeader/UnauthorizedHeader'
import LogInForm from '@/features/authentication/LogInForm/LogInForm'
import {
  useLoginMutation,
  useResetPasswordMutation,
} from '@/redux-store/thingsboard/authentication/authentication.service'
import type { LoginQueryArgs } from '@/redux-store/thingsboard/authentication/types'
import { getStorageItem, setStorageItem } from '@/utils/storage'

const { Title } = Typography

export default function LogInContainer() {
  const [showForgotPaswordModal, setShowForgotPasswordModal] = useState(false)
  const [login, { isLoading: isLoginLoading }] = useLoginMutation({
    fixedCacheKey: LOGIN_MUTATION_FIXED_CACHE_KEY,
  })
  const [
    resetPassword,
    {
      isLoading: isResetPasswordLoading,
      isSuccess: isForgotPasswordSuccessful,
      reset: resetForgotPasswordData,
    },
  ] = useResetPasswordMutation()

  const handleSubmit = (loginCredentials: LoginQueryArgs) => {
    login(loginCredentials)
  }

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordModal(true)
  }

  const handleForgotPasswordSubmit = (email: string) => {
    resetPassword({ email })
  }

  const handleCloseResetPasswordModal = () => {
    setShowForgotPasswordModal(false)
  }

  const handleRememberMeClick = () => {
    const rememberMe = getStorageItem(REMEMBER_ME)
    setStorageItem({ key: REMEMBER_ME, value: !rememberMe })
  }

  useEffect(() => {
    if (isForgotPasswordSuccessful) {
      setShowForgotPasswordModal(false)
    }
  }, [isForgotPasswordSuccessful])

  return (
    <Layout className='h-100'>
      <UnauthorizedHeader />
      <Row className='h-100 w-100 absolute' justify='end'>
        <Col className='h-100' xs={0} md={12} lg={13}>
          <LogInImage />
        </Col>
      </Row>
      <Row className='flex-1 w-100'>
        <Col className='px-3 h-100' xs={24} md={12} lg={11}>
          <Row className='h-100 flex-col justify-content-center'>
            <div>
              <Title className='m-0 text-left general-white-black'>Welcome back!</Title>
              <Title className='mb-1.5 mt-0 text-left system-gray-400-200' level={3}>
                Sign in to access the platform.
              </Title>
            </div>
            <LogInForm
              handleSubmit={handleSubmit}
              isLoading={isLoginLoading}
              handleForgotPasswordClick={handleForgotPasswordClick}
              handleRememberMeClick={handleRememberMeClick}
            />
          </Row>
        </Col>
      </Row>
      <AppFooter />
      <ResetPasswordModal
        show={showForgotPaswordModal}
        handleSubmit={handleForgotPasswordSubmit}
        isLoading={isResetPasswordLoading}
        handleClose={handleCloseResetPasswordModal}
      />
      <ActionModal
        btnText='OK'
        show={isForgotPasswordSuccessful}
        onClose={resetForgotPasswordData}
        onClick={resetForgotPasswordData}
        description='Check your Email for further instructions on resetting your password.'
        title='Sent'
        iconName='check-circle'
      />
    </Layout>
  )
}
