import { Column } from '@ant-design/charts'
import { Space, Typography } from 'antd'
import colors from 'styles/colors.module.scss'

import { THEME } from '@/constants/storageKeys'
import { UtilityType, UtilityUnitsByType } from '@/constants/utilities'
import { Theme } from '@/redux-store/theme/types'
import type { UsageChartDataFormat } from '@/redux-store/thingsboard/telemetry/types'
import { getChartConfig } from '@/utils/chartConfig'
import { getStorageItem } from '@/utils/storage'

const { Title, Text } = Typography

type PerformanceChartProps = {
  utilityType?: UtilityType
  data: Array<UsageChartDataFormat>
  darkMode: boolean
}
const fieldName = 'type'
const getColumnColor = ({ [fieldName]: type }: Record<string, string>) => {
  const currentTheme = getStorageItem(THEME)
  if (type.endsWith('Actual')) {
    return colors.skyBlue500
  } else if (type.endsWith('Over')) {
    return colors.generalRed
  }
  return currentTheme === Theme.LIGHT ? colors.systemGray200 : colors.generalWhite
}

const columnConfig = getChartConfig({ fieldName, getColor: getColumnColor })

export default function PerformanceChart(props: PerformanceChartProps) {
  const { utilityType, data } = props
  let title = `${utilityType}`
  if (utilityType === UtilityType.ELECTRICITY) {
    title = 'Electrical'
  }

  return (
    <Space
      direction='vertical'
      className='w-100 b-radius-12 input-shadow pt-1 pb-1 pl-1 bg-system-light-50-dark-100'
      prefixCls='w-100'
      size={40}
    >
      <Space className='justify-content-between w-100 pr-2'>
        <Space size='small' className='align-center'>
          <Title level={5} className='m-0 general-white-black'>
            {title} Performance
          </Title>
          {!!utilityType && (
            <Text className='m-0 xs-medium system-gray-500-300'>
              {UtilityUnitsByType[utilityType]}
            </Text>
          )}
        </Space>
      </Space>
      <Column {...columnConfig} data={data} />
    </Space>
  )
}
