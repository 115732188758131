import { Switch } from 'antd'
import React from 'react'

import { Icon } from '@/components'
import { THEME } from '@/constants/storageKeys'
import { useAppDispatch, useAppSelector } from '@/redux-store/hooks'
import { getIsDarkThemeSelector } from '@/redux-store/theme/theme.selectors'
import { setDarkTheme } from '@/redux-store/theme/theme.slice'
import { Theme } from '@/redux-store/theme/types'
import { setStorageItem } from '@/utils/storage'

export default function ThemeSwitch() {
  const dispatch = useAppDispatch()
  const darkMode = useAppSelector(getIsDarkThemeSelector)

  const toggleMode = () => {
    if (document.documentElement.getAttribute('data-theme') === Theme.LIGHT) {
      document.documentElement.setAttribute('data-theme', Theme.DARK)
      setStorageItem({ key: THEME, value: Theme.DARK })
      dispatch(setDarkTheme(true))
    } else {
      document.documentElement.setAttribute('data-theme', Theme.LIGHT)
      setStorageItem({ key: THEME, value: Theme.LIGHT })
      dispatch(setDarkTheme(false))
    }
  }

  return (
    <div className='d-flex align-center p-0.5'>
      <div className='p-0.5'>
        <Icon iconName='sun' type='solid' size='xs' className='system-gray-700-100' />
      </div>
      <Switch checked={darkMode} onChange={toggleMode} size='small' />
      <div className='p-0.5'>
        <Icon iconName='moon' type='solid' size='xs' className='system-gray-700-100' />
      </div>
    </div>
  )
}
