import { Form, Input, Typography } from 'antd'

import { ActionModal } from '@/components'
import InputErrorMessage from '@/features/authentication/components/InputErrorMessage/InputErrorMessage'

const { Text } = Typography

type ResetPasswordModalPropsType = {
  show: boolean
  handleSubmit: (email: string) => void
  isLoading: boolean
  handleClose: () => void
}

const inputName = 'email'

export default function ResetPasswordModal(props: ResetPasswordModalPropsType) {
  const { show, handleSubmit, isLoading, handleClose } = props

  const [form] = Form.useForm<{ email: string }>()
  const onClick = async () => {
    let areFieldsValidated = false
    let email = ''
    try {
      email = (await form.validateFields([inputName])).email
      areFieldsValidated = true
    } catch (error) {
      areFieldsValidated = false
    }
    if (areFieldsValidated) {
      handleSubmit(email)
      form.setFieldValue(inputName, '')
    }
  }

  return (
    <ActionModal
      show={show}
      onClose={handleClose}
      description="We'll send you a link that lets you reset your account password."
      btnText='Reset Password'
      title='Reset Password'
      onClick={onClick}
      btnDisabled={isLoading}
      iconName='envelope'
    >
      <Form
        name='reset-password'
        layout='vertical'
        requiredMark={false}
        autoComplete='off'
        size='large'
        className='w-100'
        form={form}
      >
        <Form.Item
          label={<Text className='s-medium general-black-white'>Email Address:</Text>}
          name={inputName}
          className='s-medium'
          rules={[
            {
              message: <InputErrorMessage message='Invalid email' />,
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input className='s-regular bg-general-white-gray-900 general-white-black' />
        </Form.Item>
      </Form>
    </ActionModal>
  )
}
