export type LoginQueryArgs = {
  username: string
  password: string
}

export type RefreshTokenQueryArgs = {
  refreshToken: string
}

export type ResetPasswordQueryArgs = {
  email: string
}

export type LoginResponse = {
  token: string
  refreshToken: string
}

export enum AuthorityEntityEnum {
  BILLING_ADMIN = 'BILLING_ADMIN',
  BILLING_SERVICE = 'BILLING_SERVICE',
  CUSTOMER_USER = 'CUSTOMER_USER',
  PRE_VERIFICATION_TOKEN = 'PRE_VERIFICATION_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  SYS_ADMIN = 'SYS_ADMIN',
  TENANT_ADMIN = 'TENANT_ADMIN',
}

export enum OwnerEntityEnum {
  ALARM = 'ALARM',
  API_USAGE_STATE = 'API_USAGE_STATE',
  ASSET = 'ASSET',
  ASSET_PROFILE = 'ASSET_PROFILE',
  BILLING_CUSTOMER = 'BILLING_CUSTOMER',
  BLOB_ENTITY = 'BLOB_ENTITY',
  CONVERTER = 'CONVERTER',
  COUPON = 'COUPON',
  CUSTOMER = 'CUSTOMER',
  DASHBOARD = 'DASHBOARD',
  DEVICE = 'DEVICE',
  DEVICE_PROFILE = 'DEVICE_PROFILE',
  EDGE = 'EDGE',
  ENTITY_GROUP = 'ENTITY_GROUP',
  ENTITY_VIEW = 'ENTITY_VIEW',
  GROUP_PERMISSION = 'GROUP_PERMISSION',
  INTEGRATION = 'INTEGRATION',
  OTA_PACKAGE = 'OTA_PACKAGE',
  QUEUE = 'QUEUE',
  ROLE = 'ROLE',
  RPC = 'RPC',
  RULE_CHAIN = 'RULE_CHAIN',
  RULE_NODE = 'RULE_NODE',
  SCHEDULER_EVENT = 'SCHEDULER_EVENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  SUBSCRIPTION_PLAN = 'SUBSCRIPTION_PLAN',
  TB_RESOURCE = 'TB_RESOURCE',
  TENANT = 'TENANT',
  TENANT_PROFILE = 'TENANT_PROFILE',
  USER = 'USER',
  WIDGETS_BUNDLE = 'WIDGETS_BUNDLE',
  WIDGET_TYPE = 'WIDGET_TYPE',
}

export type UserResult = {
  id: {
    id: string
    entityType: 'USER'
  }
  createdTime: EpochTimeStamp
  tenantId: {
    id: string
    entityType: 'TENANT'
  }
  customerId: {
    id: string
    entityType: 'CUSTOMER'
  }
  email: string
  name: string
  authority: AuthorityEntityEnum
  firstName: string
  lastName: string
  additionalInfo: {}
  ownerId: {
    id: string
    entityType: OwnerEntityEnum
  }
}

export type DecodedTokenType = {
  sub: string
  scopes: string[]
  userId: string
  enabled: true
  isPublic: false
  tenantId: string
  customerId: string
  iss: string
  iat: EpochTimeStamp
  exp: EpochTimeStamp
}

export type LoginResult = Pick<DecodedTokenType, 'exp'> & LoginResponse

export enum UserRoles {
  TENANT_ADMIN = 'TENANT_ADMIN',
  CUSTOMER_USER = 'CUSTOMER_USER',
}
