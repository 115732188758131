import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function GasIcon(props: DimensionProps) {
  const { width = '49', height = '48' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 49 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_23_311)'>
        <rect x='4.37598' y='3' width='40' height='40' rx='20' fill='white' />
        <g clipPath='url(#clip0_23_311)'>
          <path
            d='M14.043 25.5833C14.043 23.3229 14.5812 21.3101 15.6576 19.5448C16.7339 17.7795 17.918 16.2941 19.2096 15.0885C20.5013 13.883 21.6853 12.9625 22.7617 12.327L24.3763 11.375V15.6375C24.3763 16.434 24.6454 17.0635 25.1836 17.5259C25.7218 17.9892 26.3246 18.2208 26.9919 18.2208C27.3579 18.2208 27.7079 18.1455 28.0421 17.9948C28.3753 17.8441 28.6819 17.5965 28.9617 17.2521L29.543 16.5417C31.093 17.4458 32.3416 18.6996 33.2888 20.303C34.236 21.9072 34.7096 23.6674 34.7096 25.5833C34.7096 27.4778 34.2468 29.2052 33.3211 30.7655C32.3954 32.3267 31.1791 33.5594 29.6721 34.4635C30.0381 33.9469 30.3236 33.3816 30.5285 32.7676C30.7326 32.1545 30.8346 31.5035 30.8346 30.8146C30.8346 29.9535 30.6732 29.141 30.3503 28.3772C30.0273 27.6125 29.5645 26.9288 28.9617 26.326L24.3763 21.8375L19.8232 26.326C19.1989 26.9503 18.7253 27.6392 18.4023 28.3927C18.0794 29.1462 17.918 29.9535 17.918 30.8146C17.918 31.5035 18.0204 32.1545 18.2254 32.7676C18.4295 33.3816 18.7145 33.9469 19.0805 34.4635C17.5735 33.5594 16.3572 32.3267 15.4315 30.7655C14.5058 29.2052 14.043 27.4778 14.043 25.5833ZM24.3763 25.4542L27.1211 28.1344C27.4871 28.5003 27.7669 28.9094 27.9607 29.3615C28.1544 29.8135 28.2513 30.2979 28.2513 30.8146C28.2513 31.8694 27.8746 32.768 27.1211 33.5103C26.3676 34.2534 25.4527 34.625 24.3763 34.625C23.2999 34.625 22.385 34.2534 21.6315 33.5103C20.878 32.768 20.5013 31.8694 20.5013 30.8146C20.5013 30.3194 20.5982 29.8407 20.7919 29.3782C20.9857 28.915 21.2655 28.5003 21.6315 28.1344L24.3763 25.4542Z'
            fill='url(#paint0_linear_23_311)'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_dd_23_311'
          x='0.375977'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_23_311'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_23_311' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_23_311'
            result='effect2_dropShadow_23_311'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_23_311'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_23_311'
          x1='24.3763'
          y1='11.375'
          x2='24.3763'
          y2='34.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA800' />
          <stop offset='0.578125' stopColor='#FF0000' />
          <stop offset='1' stopColor='#FF8A00' />
        </linearGradient>
        <clipPath id='clip0_23_311'>
          <rect width='31' height='31' fill='white' transform='translate(8.87598 7.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}
