import { Col, Row, Typography } from 'antd'
import React from 'react'

import type { StatsRowPropsType } from '@/components/SystemConsumptionList/SystemConsumptionCard/types'

const { Text } = Typography

function StatsRow(props: StatsRowPropsType) {
  const { icon, actual, modelled, saved, unit } = props

  return (
    <Row align='middle' justify='end'>
      <Col span={3}>{icon}</Col>
      {[actual, modelled, saved].map((value, index) => (
        <Col span={7} key={index}>
          <Text className='s-regular general-white-black'>
            {value} {unit}
          </Text>
        </Col>
      ))}
    </Row>
  )
}

export default StatsRow
