import { Col, Row, Space, Tag, Typography } from 'antd'
import React from 'react'

import Icon from '@/components/Icon/Icon'
import type { SystemConsumptionCardInfoPropsType } from '@/components/SystemConsumptionList/SystemConsumptionCard/types'
import { capitalizeWordsAndReplaceUnderscores } from '@/utils/stringUtils'

const { Text } = Typography

export default function InfoContainer(props: SystemConsumptionCardInfoPropsType) {
  const { title, subtitle, numOfItems, numOfInefficiencies, numOfFaults, onClickDetails } = props

  const handleClick = () => onClickDetails(title)

  return (
    <Space direction='vertical' align='start' className='pl-0.75 pr-0.75'>
      <Row>
        <Col>
          <Text className='l-semibold general-white-black'>
            {capitalizeWordsAndReplaceUnderscores(title)}
          </Text>
        </Col>
      </Row>
      <Row>
        <Space direction='vertical' align='start'>
          <Col>
            <Space>
              <Icon iconName='wrench' type='solid' size='xs' className='system-gray-700-100' />
              <Text className={`s-medium system-gray-700-100`}>{subtitle}</Text>
            </Space>
          </Col>
          <Col>
            <Row gutter={[0, 6]}>
              {(numOfInefficiencies > 0 || numOfFaults > 0) && (
                <>
                  <Tag className='bg-transparent b-radius-4'>
                    <Text className={`subtitle system-gray-500-300`}>
                      {numOfInefficiencies} INEFFICIENCIES
                    </Text>
                  </Tag>
                  <Tag className='bg-general-red b-radius-4'>
                    <Text className='subtitle general-white'>{numOfFaults} FAULTS</Text>
                  </Tag>
                </>
              )}
            </Row>
          </Col>
        </Space>
      </Row>
      {/* TODO: Uncomment this when data for modal is ready */}
      <Row>
        <Col>
          <Space align='center' className='mt-0.5 pointer' onClick={handleClick}>
            <Icon iconName='expand-alt' type='regular' className='general-white-black' />
            <Text className='s-semibold general-white-black'>Details</Text>
            <Text className='s-regular system-gray-700-100'>{`(${numOfItems} Items)`}</Text>
          </Space>
        </Col>
      </Row>
    </Space>
  )
}
