import { Button, Row, Space, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import colors from 'styles/colors.module.scss'

import ProfileDrawer from '@/components/AppHeader/Drawers/ProfileDrawer'
import SiteDrawer from '@/components/AppHeader/Drawers/SiteDrawer'
import NavigationMenu from '@/components/AppHeader/Menus/NavigationMenu'
import ProfileMenu from '@/components/AppHeader/Menus/ProfileMenu'
import Icon from '@/components/Icon/Icon'
import LiveBar from '@/components/LiveBar/LiveBar'
import Logo from '@/components/Logo/Logo'
import ThemeSwitch from '@/components/ThemeSwitch/ThemeSwitch'
import { TOKEN_STORAGE_KEY } from '@/constants/storageKeys'
import { useScreenSize } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/redux-store/hooks'
import { getCurrentAssetsDataSelector } from '@/redux-store/thingsboard/assets/assets.selectors'
import { useGetUserQuery } from '@/redux-store/thingsboard/authentication/authentication.service'
import { setIsLoggedIn } from '@/redux-store/thingsboard/authentication/authentication.slice'
import { getUserScope, isTenantAdmin } from '@/utils/auth'
import { getStorageItem } from '@/utils/storage'
import { capitalizeReplaceAndRemoveFirst } from '@/utils/stringUtils'

const { Text } = Typography

export default function AppHeader() {
  const [isSiteDrawerOpen, setIsSiteDrawerOpen] = useState(false)
  const [isUserProfileDrawerOpen, setIsUserProfileDrawerOpen] = useState(false)
  const { currentData: userData } = useGetUserQuery()
  const { isScreenMobile } = useScreenSize()
  const currentAsset = useAppSelector(getCurrentAssetsDataSelector)
  const roles = useMemo(() => getUserScope(getStorageItem(TOKEN_STORAGE_KEY)), [])

  const isAdmin = isTenantAdmin(roles)

  const { firstName = '', lastName = '', authority } = userData || {}

  const dispatch = useAppDispatch()

  const logoutUser = () => {
    dispatch(setIsLoggedIn(false))
  }

  function openUserProfileDrawer() {
    setIsUserProfileDrawerOpen(true)
  }

  function closeUserProfileDrawer() {
    setIsUserProfileDrawerOpen(false)
  }

  function openSiteDrawer() {
    if (isAdmin) {
      setIsSiteDrawerOpen(true)
    }
  }
  function closeSiteDrawer() {
    setIsSiteDrawerOpen(false)
  }

  return (
    <div>
      <Space.Compact block className='nav-shadow pt-0.5 pb-0.5 px-2' size='large'>
        <Space align='center' className='mr-2' size='large'>
          <Logo logoLength='short' height={24} />
          <Space onClick={openSiteDrawer} className='pointer w-fit-content'>
            <Text className='s-semibold general-white-black'>
              {capitalizeReplaceAndRemoveFirst(currentAsset.name, '_')}
            </Text>
            <Icon iconName='chevron-left' type='regular' size='sm' color={colors.systemGray400} />
          </Space>
        </Space>
        {isScreenMobile ? (
          <Space className='ml-auto'>
            <Button
              type='text'
              onClick={openUserProfileDrawer}
              icon={<Icon iconName='menu' type='regular' />}
            />
          </Space>
        ) : (
          <Space className='justify-content-between flex-1'>
            <NavigationMenu />
            <Space>
              <ThemeSwitch />
              <ProfileMenu
                user={`${firstName || 'John'}, ${lastName || 'Doe'}`}
                role={authority}
                logoutUser={logoutUser}
              />
            </Space>
          </Space>
        )}

        <ProfileDrawer
          isOpen={isUserProfileDrawerOpen}
          onClose={closeUserProfileDrawer}
          onLogout={logoutUser}
        />
        {isAdmin && <SiteDrawer isOpen={isSiteDrawerOpen} onClose={closeSiteDrawer}></SiteDrawer>}
      </Space.Compact>
      <Row>
        <LiveBar />
      </Row>
    </div>
  )
}
