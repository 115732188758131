import type { MenuProps } from 'antd'
import { Dropdown, Space, Typography } from 'antd'
import type { ReactElement } from 'react'

import styles from './MenuDropdown.module.scss'

type DropdownPropsType = {
  header: { title: string; subtitle: string }
  menuItems: MenuProps['items']
  triggerButton: ReactElement
}

const { Text } = Typography

const MenuDropdown = (props: DropdownPropsType) => {
  const { triggerButton, header, menuItems } = props
  const items: MenuProps['items'] = [
    {
      disabled: true,
      key: 'non-clickable-header',
      label: (
        <Space
          size='small'
          direction='vertical'
          align='start'
          className={styles['header-container']}
        >
          <Text className='m-semibold general-white-black'>{header.title}</Text>
          <Text className={`xs-regular ${styles.subtitle} system-gray-500-200`}>
            {header.subtitle}
          </Text>
        </Space>
      ),
    },
    ...(menuItems || []),
  ]

  return (
    <Space>
      <Dropdown
        menu={{ items }}
        placement='bottomRight'
        trigger={['click']}
        className={styles['dropdown']}
      >
        {triggerButton}
      </Dropdown>
    </Space>
  )
}

export default MenuDropdown
