import { Space, Typography } from 'antd'

import { ComfortScoresText, UtilityType } from '@/constants/utilities'

import styles from './LiveDataPill.module.scss'
const { Text } = Typography

type LiveVsTargetDataPill = {
  liveData: number
  utilityType: UtilityType
}

export default function LiveDataPill(props: LiveVsTargetDataPill) {
  const { liveData, utilityType } = props

  let data: string | number = liveData

  if (utilityType === UtilityType.COMFORT_SCORE) {
    data = ComfortScoresText[liveData]
  }

  return (
    <Space.Compact className={`${styles['live-data-container']} pill-shadow`}>
      <div className={`${styles['live-data']}`}>
        <Text className='xs-semibold general-white-black'>{data}</Text>
      </div>
    </Space.Compact>
  )
}
