import { Image } from 'antd'

export default function LogInImage() {
  return (
    <div className='w-100 h-100 d-flex align-end'>
      <div className='absolute h-100 w-100 z-1 pl-5'>
        <Image
          wrapperClassName='h-100 w-100'
          src='/images/background.png'
          preview={false}
          alt='background'
          className='h-100'
        />
      </div>
      <div className='z-2'>
        <Image src='/images/buildings.png' preview={false} alt='buildings' />
      </div>
    </div>
  )
}
