import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function GasIcon(props: DimensionProps) {
  const { width = '49', height = '48' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_1094_2443)'>
        <rect x='4' y='3' width='40' height='40' rx='20' fill='url(#paint0_linear_1094_2443)' />
        <g clipPath='url(#clip0_1094_2443)'>
          <path
            d='M13.6666 25.5833C13.6666 23.3229 14.2048 21.3101 15.2812 19.5448C16.3576 17.7795 17.5416 16.2941 18.8333 15.0885C20.125 13.883 21.309 12.9625 22.3854 12.327L24 11.375V15.6375C24 16.434 24.2691 17.0635 24.8073 17.5259C25.3454 17.9892 25.9482 18.2208 26.6156 18.2208C26.9816 18.2208 27.3316 18.1455 27.6657 17.9948C27.999 17.8441 28.3055 17.5965 28.5854 17.2521L29.1666 16.5417C30.7166 17.4458 31.9652 18.6996 32.9125 20.303C33.8597 21.9072 34.3333 23.6674 34.3333 25.5833C34.3333 27.4778 33.8704 29.2052 32.9447 30.7655C32.0191 32.3267 30.8027 33.5594 29.2958 34.4635C29.6618 33.9469 29.9472 33.3816 30.1522 32.7676C30.3563 32.1545 30.4583 31.5035 30.4583 30.8146C30.4583 29.9535 30.2968 29.141 29.9739 28.3772C29.651 27.6125 29.1882 26.9288 28.5854 26.326L24 21.8375L19.4468 26.326C18.8225 26.9503 18.3489 27.6392 18.026 28.3927C17.7031 29.1462 17.5416 29.9535 17.5416 30.8146C17.5416 31.5035 17.6441 32.1545 17.849 32.7676C18.0531 33.3816 18.3382 33.9469 18.7041 34.4635C17.1972 33.5594 15.9809 32.3267 15.0552 30.7655C14.1295 29.2052 13.6666 27.4778 13.6666 25.5833ZM24 25.4542L26.7448 28.1344C27.1107 28.5003 27.3906 28.9094 27.5843 29.3615C27.7781 29.8135 27.875 30.2979 27.875 30.8146C27.875 31.8694 27.4982 32.768 26.7448 33.5103C25.9913 34.2534 25.0763 34.625 24 34.625C22.9236 34.625 22.0086 34.2534 21.2552 33.5103C20.5017 32.768 20.125 31.8694 20.125 30.8146C20.125 30.3194 20.2218 29.8407 20.4156 29.3782C20.6093 28.915 20.8892 28.5003 21.2552 28.1344L24 25.4542Z'
            fill='url(#paint1_linear_1094_2443)'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_dd_1094_2443'
          x='0'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_1094_2443'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1094_2443' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_1094_2443'
            result='effect2_dropShadow_1094_2443'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_1094_2443'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1094_2443'
          x1='24'
          y1='3'
          x2='24'
          y2='43'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D4D4D' />
          <stop offset='1' stopColor='#343434' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1094_2443'
          x1='24'
          y1='11.375'
          x2='24'
          y2='34.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFB628' />
          <stop offset='0.578125' stopColor='#EF7575' />
          <stop offset='1' stopColor='#FF8E0A' />
        </linearGradient>
        <clipPath id='clip0_1094_2443'>
          <rect width='31' height='31' fill='white' transform='translate(8.5 7.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}
