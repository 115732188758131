import { Image, Space, Typography } from 'antd'
import noop from 'lodash/noop'
import React from 'react'

import Icon from '@/components/Icon/Icon'
import { capitalizeReplaceAndRemoveFirst } from '@/utils/stringUtils'
import colors from 'styles/colors.module.scss'

type SiteCardPropsType = {
  id?: number
  name: string
  onClick: () => void
}

const { Text } = Typography

export default function SiteCard(props: SiteCardPropsType) {
  const { name, onClick = noop } = props

  return (
    <Space
      className='bg-general-white-dark-100 b-radius-8 input-shadow p-0.75 pointer w-100'
      onClick={onClick}
    >
      <Image src='/images/building.png' preview={false} alt='building' />
      <Space.Compact block direction='vertical'>
        <Space.Compact className='align-center'>
          <Icon iconName='check-circle' type='solid' size='xs' color={colors.skyBlue} />
          <Space.Compact block className='justify-content-between'>
            <Text className='m-medium general-white-black ml-0.25'>
              {capitalizeReplaceAndRemoveFirst(name, '_')}
            </Text>
          </Space.Compact>
        </Space.Compact>
      </Space.Compact>
    </Space>
  )
}
