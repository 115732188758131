import { Col, Row, Space, Typography } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { OverallPerformanceStatus, TimePeriodSelect } from '@/components'
import Tabs from '@/components/Tabs/Tabs'
import { POLLING_INTERVAL_IN_MS, UtilityType } from '@/constants/utilities'
import PerformanceChart from '@/features/usage/components/PerformanceChart'
import { useScreenSize } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/redux-store/hooks'
import { getIsDarkThemeSelector } from '@/redux-store/theme/theme.selectors'
import {
  getActiveUsageTabSelector,
  getSelectedTimePeriodSelector,
} from '@/redux-store/thingsboard/telemetry/telemetry.selectors'
import {
  useGetBenchmarkValuesQuery,
  useGetSummaryTelemetryQuery,
} from '@/redux-store/thingsboard/telemetry/telemetry.service'
import { setActiveUsageTab } from '@/redux-store/thingsboard/telemetry/telemetry.slice'
import type { UsageChartDataFormat } from '@/redux-store/thingsboard/telemetry/types'
import { createTargetUsageChartData, createUsageChartDataObject } from '@/utils'

const { Text } = Typography

export default function PerformanceContainer() {
  const { id = '' } = useParams()

  const { currentData } = useGetSummaryTelemetryQuery(id, {
    pollingInterval: POLLING_INTERVAL_IN_MS,
    skip: !id,
  })

  const dispatch = useAppDispatch()

  const selectedTimePeriod = useAppSelector(getSelectedTimePeriodSelector)
  const activeUsageTab = useAppSelector(getActiveUsageTabSelector)
  const darkMode = useAppSelector(getIsDarkThemeSelector)
  const { runningUsage, onTrack } = currentData || {}

  const { currentData: { benchmarkValues } = {} } = useGetBenchmarkValuesQuery(id, {
    pollingInterval: POLLING_INTERVAL_IN_MS,
    skip: !id,
  })

  const { isScreenDesktop } = useScreenSize()

  const {
    elec: targetElectricity,
    gas: targetGas,
    carbon: targetCarbon,
    fiscal: targetFiscal,
    water: targetWater,
  } = benchmarkValues || {}

  const isCarbonOnTrack = onTrack?.carbon?.[selectedTimePeriod] || false
  const isElectricityOnTrack = onTrack?.primary_electricity?.[selectedTimePeriod] || false
  const isFiscalOnTrack = onTrack?.fiscal?.[selectedTimePeriod] || false
  const isGasOnTrack = onTrack?.primary_gas?.[selectedTimePeriod] || false
  const isWaterOnTrack = onTrack?.primary_water?.[selectedTimePeriod] || false

  const showGasData = !isEmpty(targetGas)
  const showElectricityData = !isEmpty(targetElectricity)
  const showWaterData = !isEmpty(targetWater)
  const showCarbonData = !isEmpty(targetCarbon)
  const showFiscalData = !isEmpty(targetFiscal)

  const { chartData, utilityType } = useMemo(() => {
    let newChartData: UsageChartDataFormat[] = []
    let utilityType: UtilityType | undefined = undefined
    if (!runningUsage) return { chartData: newChartData, utilityType }
    const { primary_electricity, primary_gas, primary_water, carbon, fiscal } = runningUsage
    let utilityUsageForGivenTimePeriod: UsageChartDataFormat[] = []
    let targetUtilityBenchmark: number[] = []
    switch (activeUsageTab) {
      case UtilityType.ELECTRICITY:
        utilityUsageForGivenTimePeriod = primary_electricity?.[selectedTimePeriod] || []
        targetUtilityBenchmark = targetElectricity?.[selectedTimePeriod] || []
        utilityType = UtilityType.ELECTRICITY
        break
      case UtilityType.CARBON:
        utilityUsageForGivenTimePeriod = carbon?.[selectedTimePeriod] || []
        targetUtilityBenchmark = targetCarbon?.[selectedTimePeriod] || []
        utilityType = UtilityType.CARBON
        break
      case UtilityType.FISCAL:
        utilityUsageForGivenTimePeriod = fiscal?.[selectedTimePeriod] || []
        targetUtilityBenchmark = targetFiscal?.[selectedTimePeriod] || []
        utilityType = UtilityType.FISCAL
        break
      case UtilityType.GAS:
        utilityUsageForGivenTimePeriod = primary_gas?.[selectedTimePeriod] || []
        targetUtilityBenchmark = targetGas?.[selectedTimePeriod] || []
        utilityType = UtilityType.GAS
        break
      case UtilityType.WATER:
        utilityUsageForGivenTimePeriod = primary_water?.[selectedTimePeriod] || []
        targetUtilityBenchmark = targetWater?.[selectedTimePeriod] || []
        utilityType = UtilityType.WATER
        break
      case UtilityType.COMFORT_SCORE:
        utilityType = UtilityType.COMFORT_SCORE
    }
    const newUtilityUsageForGivenTimePeriod = utilityUsageForGivenTimePeriod.map(
      ({ timeFrame, value }, index) => {
        return createUsageChartDataObject({
          benchmark: value,
          isActual: true,
          isOverBenchmark: value > targetUtilityBenchmark[index],
          name: utilityType!,
          timeFrame,
        })
      }
    )
    const newUtilityRunningUsageChartData = createTargetUsageChartData({
      runningUsageData: newUtilityUsageForGivenTimePeriod,
      targetBenchmark: targetUtilityBenchmark,
      utilityType,
    })
    newChartData = newChartData.concat(
      newUtilityUsageForGivenTimePeriod,
      newUtilityRunningUsageChartData
    )
    return { chartData: newChartData, utilityType }
  }, [
    runningUsage,
    targetElectricity,
    targetGas,
    targetCarbon,
    targetFiscal,
    targetWater,
    selectedTimePeriod,
    activeUsageTab,
  ])

  const handleTabChange = (label: string) => {
    dispatch(setActiveUsageTab(label as UtilityType))
  }

  const tabItems = useMemo(() => {
    const items: UtilityType[] = []
    if (showGasData) items.push(UtilityType.GAS)
    if (showElectricityData) items.push(UtilityType.ELECTRICITY)
    if (showWaterData) items.push(UtilityType.WATER)
    if (showCarbonData) items.push(UtilityType.CARBON)
    if (showFiscalData) items.push(UtilityType.FISCAL)
    return items.map((item) => ({ label: item }))
  }, [showGasData, showElectricityData, showWaterData, showCarbonData, showFiscalData])

  let isPerformanceSuccess = false
  switch (activeUsageTab) {
    case UtilityType.ELECTRICITY:
      isPerformanceSuccess = isElectricityOnTrack
      break
    case UtilityType.CARBON:
      isPerformanceSuccess = isCarbonOnTrack
      break
    case UtilityType.FISCAL:
      isPerformanceSuccess = isFiscalOnTrack
      break
    case UtilityType.GAS:
      isPerformanceSuccess = isGasOnTrack
      break
    case UtilityType.WATER:
      isPerformanceSuccess = isWaterOnTrack
      break
  }
  return (
    <Space.Compact direction='vertical' block>
      <Row gutter={[16, 32]} align='middle' justify='space-between'>
        <Col xs={24} lg={18}>
          <Tabs
            items={tabItems}
            onChange={handleTabChange}
            activeTab={activeUsageTab}
            className='w-fit-content'
          />
        </Col>
        <Col xs={24} lg={6}>
          <Row justify={!isScreenDesktop ? 'start' : 'end'}>
            <TimePeriodSelect />
          </Row>
        </Col>
      </Row>

      <Space.Compact className='w-100' direction='vertical' block>
        <Space className='mt-2' direction='vertical' align='start'>
          <Text className='subtitle system-gray-500-300'>Overall Performance</Text>
          <OverallPerformanceStatus isStatusSuccess={isPerformanceSuccess} />
        </Space>
        <div className='mt-2'>
          <PerformanceChart utilityType={utilityType} data={chartData} darkMode={darkMode} />
        </div>
      </Space.Compact>
    </Space.Compact>
  )
}
