import ky from 'ky'

import { TOKEN_STORAGE_KEY } from '@/constants/storageKeys'
import { getStorageItem } from '@/utils/storage'

export const extendedKy = ky.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        const token = getStorageItem(TOKEN_STORAGE_KEY)
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

export const fetchFn:
  | ((input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>)
  | undefined = (...args) => {
  return extendedKy(...args)
}
