import { Col, Row, Space, Typography } from 'antd'
import React from 'react'

import { FullScreenButton, SystemConsumptionList } from '@/components'
import PerformanceContainer from '@/features/usage/components/PerformanceContainer'
import { useScreenSize } from '@/hooks'

const { Text, Title } = Typography

function UsageContainer() {
  const { isScreenMobile, isScreenDesktop } = useScreenSize()
  return (
    <Space.Compact
      direction='vertical'
      className={`w-100 pt-2 ${isScreenMobile ? 'pl-1 pr-1' : 'pl-6 pr-6'}`}
    >
      <Row gutter={48} justify='space-between'>
        <Col span={24} xl={12}>
          <Row>
            <Col span={24}>
              <Row>
                <Col xs={24} lg={12} className='d-flex justify-content-start'>
                  <Title className='general-white-black'>Usage</Title>
                </Col>
                {isScreenDesktop && (
                  <Col lg={12} className='d-flex justify-content-end'>
                    <FullScreenButton />
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24} className='pt-1.5'>
              <PerformanceContainer />
            </Col>
          </Row>
        </Col>
        <Col span={24} xl={12} className='pt-1 pl-2'>
          <Text className='subtitle system-gray-500-300'>Consumption By System</Text>
          <Space.Compact block direction='vertical' size='large' className='mt-1'>
            <SystemConsumptionList />
          </Space.Compact>
        </Col>
      </Row>
    </Space.Compact>
  )
}

export default UsageContainer
