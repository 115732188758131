import type { DimensionProps } from '@/components/LiveDataIcons/types'

export default function WaterIcon(props: DimensionProps) {
  const { width = '49', height = '48' } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_1094_2422)'>
        <rect x='4' y='3' width='40' height='40' rx='20' fill='url(#paint0_linear_1094_2422)' />
        <g clipPath='url(#clip0_1094_2422)'>
          <mask
            id='mask0_1094_2422'
            style={{ maskType: 'luminance' }}
            maskUnits='userSpaceOnUse'
            x='14'
            y='10'
            width='20'
            height='26'
          >
            <path
              d='M24 33.4166C28.3146 33.4166 31.8125 29.9187 31.8125 25.6041C31.8125 18.3125 24 12.5833 24 12.5833C24 12.5833 16.1875 18.3125 16.1875 25.6041C16.1875 29.9187 19.6854 33.4166 24 33.4166Z'
              stroke='white'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M16.1875 25.6042C16.1875 29.9188 19.6854 33.4167 24 33.4167C28.3146 33.4167 31.8125 29.9188 31.8125 25.6042C31.8125 25.6042 27.125 27.1667 24 25.6042C20.875 24.0417 16.1875 25.6042 16.1875 25.6042Z'
              fill='white'
              stroke='white'
              strokeWidth='4'
              strokeLinejoin='round'
            />
          </mask>
          <g mask='url(#mask0_1094_2422)'>
            <path d='M11.5 10.5H36.5V35.5H11.5V10.5Z' fill='url(#paint1_linear_1094_2422)' />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_dd_1094_2422'
          x='0'
          y='0'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_1094_2422'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1094_2422' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_1094_2422'
            result='effect2_dropShadow_1094_2422'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_1094_2422'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1094_2422'
          x1='24'
          y1='3'
          x2='24'
          y2='43'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4D4D4D' />
          <stop offset='1' stopColor='#343434' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1094_2422'
          x1='24'
          y1='10.5'
          x2='24'
          y2='35.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#389CE4' />
          <stop offset='1' stopColor='#23F0E4' />
        </linearGradient>
        <clipPath id='clip0_1094_2422'>
          <rect width='25' height='25' fill='white' transform='translate(11.5 10.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}
