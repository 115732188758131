import { Space, Typography } from 'antd'
import capitalize from 'lodash/capitalize'

import Icon from '@/components/Icon/Icon'
import colors from 'styles/colors.module.scss'

const { Text } = Typography

type AlertCardPropsType = {
  text: string
}

export default function AlertCard(props: AlertCardPropsType) {
  const { text } = props

  return (
    <Space
      align='center'
      direction='horizontal'
      className='bg-system-light-50-dark-100 w-100 m-0.5 p-1 pointer input-shadow b-radius-8'
    >
      <Space>
        <Icon iconName='x-circle' type='solid' size='md' color={colors.generalRed} />
      </Space>
      <Space.Compact direction='vertical'>
        <Text className='m-semibold general-white-black'>{capitalize(text)}</Text>
      </Space.Compact>
    </Space>
  )
}
