import { Skeleton, Space, Typography } from 'antd'

import LiveDataPill from '@/components/UtilityLiveDataPill/LiveDataPill/LiveDataPill'
import type { UtilityType } from '@/constants/utilities'
import { UtilityUnitsText } from '@/constants/utilities'
import { useAppSelector } from '@/redux-store/hooks'
import { getIsDarkThemeSelector } from '@/redux-store/theme/theme.selectors'
import * as LiveDataIcons from 'components/LiveDataIcons'

import styles from './UtilityLiveDataPill.module.scss'

const { Text } = Typography

type UtilityLiveDataPillPropsType = {
  liveData?: number
  utilityType: UtilityType
  isLoading: boolean
}

export default function UtilityLiveDataPill(props: UtilityLiveDataPillPropsType) {
  const { liveData = 0, utilityType, isLoading } = props

  const darkMode = useAppSelector(getIsDarkThemeSelector)

  if (isLoading) return <Skeleton.Input active size='large' />

  const utilityTypeText = UtilityUnitsText[utilityType]

  const Icon = LiveDataIcons[`${utilityType}Icon${darkMode ? 'Dark' : 'Light'}`]
  return (
    <div className={`${styles.container} bg-system-light-dark-50`}>
      <Skeleton active={isLoading} loading={isLoading} round>
        <Icon />
        <Space.Compact className={styles['measurement-info']} direction='vertical'>
          <Text className='s-medium general-white-black'>{utilityTypeText}</Text>
        </Space.Compact>
        <LiveDataPill liveData={liveData} utilityType={utilityType} />
      </Skeleton>
    </div>
  )
}
